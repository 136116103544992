import './wire_selection.scss';

// External Libs
import _ from 'lodash';
import Axios from 'axios';
import React, { Component } from 'react';

// Internal Components
import Conversion from './conversion';
import ConversionComplete from './conversion_complete';
import Loading from './loading';
import Modal from '../../../../components/modal/modal';
import Override from './override';
import { removeIreqIdInitialNumber } from '../../../../common/functions';
import { handleHttpRequestError } from '../../../../common/error';
import SessionExpire from '../../../../common/session_expire';
import { getPDFNameTemplate } from '../../../../common/helpers';
class WireSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'WS Loading',
      modal: '',
      template_name: '',
      modal_file: {},
      data: [],
      refresh: false,
      adjusted_bmf_file: {},
      case_ws: [],
    };
  }

  componentDidMount() {
    const that = this;
    const { ireq_id } = { ...this.props.item_request };
    Axios.get(`/apiv3/ireqautomationstatus/${ireq_id}/ws`).then(function (res) {
      if (res && res.data && res.data.ws && res.data.ws.length > 0) {
        const details = res.data.ws_details;
        const status = res.data.ws[0]['status'];
        const status_comment = res.data.ws[0]['status_comment'];

        that.setState({
          status: status,
          status_comment: status_comment,
          last_active_status: that.getLastActiveStatus(res.data.ws),
          details: details,
          result_json: res.data.ws_result_json,
          adjusted_bmf_file: res.data.adjusted_bmf_file,
          case_ws: res.data.case_ws,
          enabled_universal: res.data.enabled_universal,
        });

        that.wsUpdatePartAndStatus(details, status);
      }
    });
  }

  getLastActiveStatus = (statuses) => {
    let last_active_status = '';
    for (let i = 0; i < statuses.length; i++) {
      if (statuses[i] !== 'WS Complete') {
        last_active_status = statuses[i]['status'];
        break;
      }
    }

    return last_active_status;
  };

  /**
   * Gets Template Info from Wire Generation data
   * @param {String} part_description Wire Description
   * @param {Object} result_json Wire Generation Status Comment
   * @param {String} template_type template type [template, universal_template]
   * @returns {String} template name
   */
  getTemplateInfoFromResults = (part_description, result_json, template_type) => {
    let template = '';
    const template_property = template_type === 'universal_template' ? 'universal_template_recommended' : 'template';

    if (result_json && 'results-collated' in result_json && 'wires' in result_json['results-collated']) {
      const description = part_description.toLowerCase().replace('2.0', '');
      const arch = description.indexOf('upper') >= 0 ? 'upper' : 'lower';
      const wire =
        description.indexOf('1') >= 0 ? 'initial' : description.indexOf('2') >= 0 ? 'intermediate' : description.indexOf('3') >= 0 ? 'final' : 'unknown';

      template =
        result_json['results-collated'] &&
        result_json['results-collated']['wires'] &&
        result_json['results-collated']['wires'][wire] &&
        result_json['results-collated']['wires'][wire][arch] &&
        result_json['results-collated']['wires'][wire][arch][template_property]
          ? result_json['results-collated']['wires'][wire][arch][template_property]
          : '';
    }

    if (template_type === 'universal_template' && template) {
      template = 'Standard';
    }
    return template;
  };

  getPartTranslation = (part_id, item) => {
    const part_def = {
      80027: {
        label: 'Lower Smartwire 0',
        pn_standard: '40351-02',
        pn_no_4: '40351-03',
        pn_one_4: '40351-04',
        pn_custom: '40351-05',
        pn_universal: '40351-06',
      },
      80028: {
        label: 'Upper Smartwire 0',
        pn_standard: '40352-02',
        pn_no_4: '40352-03',
        pn_one_4: '40352-04',
        pn_custom: '40352-05',
        pn_universal: '40352-06',
      },
      80001: {
        label: 'Lower Smartwire 1',
        pn_standard: '40375-02',
        pn_no_4: '40375-03',
        pn_one_4: '40375-04',
        pn_custom: '40375-05',
        pn_universal: '40375-06',
      },
      80002: {
        label: 'Upper Smartwire 1',
        pn_standard: '40376-02',
        pn_no_4: '40376-03',
        pn_one_4: '40376-04',
        pn_custom: '40376-05',
        pn_universal: '40376-06',
      },
      80003: {
        label: 'Lower Smartwire 2',
        pn_standard: '40377-02',
        pn_no_4: '40377-03',
        pn_one_4: '40377-04',
        pn_custom: '40377-05',
        pn_universal: '40377-06',
      },
      80004: {
        label: 'Upper Smartwire 2',
        pn_standard: '40378-02',
        pn_no_4: '40378-03',
        pn_one_4: '40378-04',
        pn_custom: '40378-05',
        pn_universal: '40378-06',
      },
      80005: {
        label: 'Lower Smartwire 3',
        pn_standard: '40379-02',
        pn_no_4: '40379-03',
        pn_one_4: '40379-04',
        pn_custom: '40379-05',
        pn_universal: '40379-06',
      },
      80006: {
        label: 'Upper Smartwire 3',
        pn_standard: '40380-02',
        pn_no_4: '40380-03',
        pn_one_4: '40380-04',
        pn_custom: '40380-05',
        pn_universal: '40380-06',
      },
      80009: {
        label: 'Lower DE Smartwire 1',
        pn_standard: '40375-02',
        pn_no_4: '40375-03',
        pn_one_4: '40375-04',
        pn_custom: '40375-05',
        pn_universal: '40375-06',
      },
      80010: {
        label: 'Upper DE Smartwire 1',
        pn_standard: '40376-02',
        pn_no_4: '40376-03',
        pn_one_4: '40376-04',
        pn_custom: '40376-05',
        pn_universal: '40376-06',
      },
      80011: {
        label: 'Lower DE Smartwire 2',
        pn_standard: '40377-02',
        pn_no_4: '40377-03',
        pn_one_4: '40377-04',
        pn_custom: '40377-05',
        pn_universal: '40377-06',
      },
      80012: {
        label: 'Upper DE Smartwire 2',
        pn_standard: '40378-02',
        pn_no_4: '40378-03',
        pn_one_4: '40378-04',
        pn_custom: '40378-05',
        pn_universal: '40378-06',
      },
      80013: {
        label: 'Lower DE Smartwire 3',
        pn_standard: '40379-02',
        pn_no_4: '40379-03',
        pn_one_4: '40379-04',
        pn_custom: '40379-05',
        pn_universal: '40379-06',
      },
      80014: {
        label: 'Upper DE Smartwire 3',
        pn_standard: '40380-02',
        pn_no_4: '40380-03',
        pn_one_4: '40380-04',
        pn_custom: '40380-05',
        pn_universal: '40380-06',
      },
    };

    if (part_id in part_def && item in part_def[part_id]) {
      return part_def[part_id][item];
    }

    return '';
  };

  /**
   * Gets Wire Template Type and Universal Template Type
   * @param {String} description wire description
   * @param {Object} result_json wire selection status comment
   * @param {String} template_type template type
   */
  getTemplateInfo = (description, result_json, template_type) => {
    let template_info = description ? this.getTemplateInfoFromResults(description, result_json, template_type) : null;

    if (template_info) {
      if (template_info === 'Standard') {
        return 'standard';
      } else if (template_info === 'No 4s') {
        return 'no_4';
      } else if (template_info === 'One 4') {
        return 'one_4';
      } else {
        return null;
      }
    }

    return template_info;
  };

  translateWire = (sw) => {
    const isManual = this.state.last_active_status === 'WS Manual Process';
    const originalSelected = sw.original_template_selected;
    const isUniversalTemplate = sw['template'] === 'universal';
    const isCustomOrAutomated = sw.manual_upload || sw['ireq_file__file_type'] === 'ws_manual' || sw['ireq_file__file_type'] === 'ws_automated';
    const isTemplate = sw['template'] === 'standard' || sw['template'] === 'no_4' || sw['template'] === 'one_4';
    const description = sw.ireq_details__ireq_parts__description;
    const result_json = this.state.result_json;
    const hasTemplateInfo = this.getTemplateInfo(description, result_json, 'template');
    const translation_type = isCustomOrAutomated
      ? 'pn_custom'
      : hasTemplateInfo && originalSelected === 'template'
      ? `pn_${hasTemplateInfo}`
      : originalSelected === 'universal_template'
      ? `pn_universal`
      : null;
    const translation_type_manual = sw['ireq_manual_process_file_id']
      ? 'pn_custom'
      : isTemplate
      ? `pn_${sw['template']}`
      : isUniversalTemplate
      ? 'pn_universal'
      : null;
    const translation = translation_type ? this.getPartTranslation(sw.ireq_details__ireq_parts__part_id, translation_type) : 'N/A';
    const translation_manual = translation_type_manual ? this.getPartTranslation(sw.ireq_details__ireq_parts__part_id, translation_type_manual) : 'N/A';

    return isManual ? translation_manual : translation;
  };

  wsUpdatePartAndStatus = (details, status) => {
    const that = this;
    const isManual = this.state.last_active_status === 'WS Manual Process';
    let has_guide = false;

    details.map((detail) => {
      const part_id = detail['ireq_details__ireq_parts__part_id'];
      that.props.updateTemplateNo(part_id, that.translateWire(detail));

      if (
        (!isManual && detail.group === 'guide' && detail.ireq_file__upload_data !== null) ||
        (isManual && detail.group === 'guide' && detail.ireq_manual_process_file__upload_data)
      ) {
        has_guide = true;
      }

      return null;
    });

    that.props.setWsStatus(status);
    that.props.hasWsGuide(has_guide);
  };

  wsAction = (action) => {
    const that = this;
    const { ireq_id } = this.props.item_request;

    Axios.post(`/apiv3/ireqautomationstatus/${ireq_id}/ws/${action}`)
      .then(function (res) {
        if (res && res.data && res.data.ws && res.data.ws.length > 0) {
          const details = res.data.ws_details;
          const status = res.data.ws[0]['status'];
          const status_comment = res.data.ws[0]['status_comment'];

          that.setState({
            status: status,
            status_comment: status_comment,
            last_active_status: that.getLastActiveStatus(res.data.ws),
            details: details,
            modal: '',
            template_name: '',
            modal_file: {},
          });

          that.wsUpdatePartAndStatus(details, status);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  wsUpload = (data, ireq_id) => {
    const that = this;

    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/upload`, data)
      .then(function (res) {
        if (res && res.data && res.data.ws && res.data.ws.length > 0) {
          const details = res.data.ws_details;
          const status = res.data.ws[0]['status'];

          that.setState({
            details: res.data.ws_details,
          });

          that.wsUpdatePartAndStatus(details, status);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  wsRemove = (data, ireq_id) => {
    const that = this;

    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/remove`, data)
      .then(function (res) {
        if (res && res.data && res.data.ws && res.data.ws.length > 0) {
          const details = res.data.ws_details;
          const status = res.data.ws[0]['status'];

          that.setState({
            details: res.data.ws_details,
            modal: '',
            data: [],
          });

          that.wsUpdatePartAndStatus(details, status);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  wsSetTemplate = (data, ireq_id) => {
    const that = this;
    let prev_template_selection = '';
    for (const detail of this.state.details) {
      if (data.ireq_wire_selection_id === detail.id) {
        prev_template_selection = detail.template;
        break;
      }
    }
    data['prev_template_selection'] = prev_template_selection;

    Axios.put(`/apiv3/ireqautomationstatus/${ireq_id}/ws/set_template`, [data])
      .then(function (res) {
        if (res && res.data && res.data.ws && res.data.ws.length > 0) {
          const details = res.data.ws_details;
          const status = res.data.ws[0]['status'];

          that.setState({
            details: res.data.ws_details,
          });

          that.wsUpdatePartAndStatus(details, status);
        }
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });
  };

  setModal = (modal, template_name = '', modal_file = {}, data = []) => {
    this.setState({
      modal: modal,
      template_name: template_name,
      modal_file: modal_file,
      data: data,
    });
  };

  clearModal = () => {
    this.setState({
      modal: '',
      template_name: '',
      modal_file: {},
      data: '',
    });
  };

  displayModal = () => {
    const that = this;
    const { modal, template_name, modal_file, data } = this.state;
    const raw_ireq_id = this.props.item_request.ireq_id;
    const ireq_id = removeIreqIdInitialNumber(this.props.item_request.ireq_id);
    const is_manual_upload = modal_file?.upload_data?.includes('manual');

    const modals = {
      convert_success: {
        header_text: `Mark as Success - ${ireq_id}`,
        message_text: `Are you sure you would like to force the wire generation process to succeed?`,
        confirm_btn_text: `Mark as Success`,
      },
      convert_failed: {
        header_text: `Mark as Failed - ${ireq_id}`,
        message_text: `Are you sure you would like to force the wire generation process to fail?`,
        confirm_btn_text: `Mark as Failed`,
      },
      manual: {
        header_text: `Manual Process - ${ireq_id}`,
        message_text: `Are you sure you would like to complete this process manually?`,
        confirm_btn_text: `Manual Process`,
      },
      override: {
        header_text: `Override Process - ${ireq_id}`,
        message_text: `Are you sure you want to override process?`,
        confirm_btn_text: `Override`,
      },
      set_template: {
        header_text: `${template_name} - ${ireq_id}`,
        message_text: `Please specify template wire type for the ${template_name} for this case.`,
        confirm_btn_text: `Save`,
      },
      remove_guide: {
        header_text: `Remove Wire Selection Guide - ${ireq_id}`,
        message_text: `Are you sure you want to remove the wire selection guide?`,
        confirm_btn_text: `Remove`,
      },
      remove_wire: {
        header_text: `Remove Custom Wire - ${ireq_id}`,
        message_text: `Are you sure you want to remove the custom wire file?`,
        confirm_btn_text: `Remove`,
      },
      pdf: {
        preset: 'pdf_viewer',
        header_text: `Wire Selection Guide - ${ireq_id}`,
        modal_size_class: 'modal-lg modal-lg-pdf',
        modal_body_class: 'modal-pdf',
        pdf_url: modal_file?.upload_data,
        pdf_date: modal_file?.modified_date,
        close_btn_text: 'Close',
        original_filename: getPDFNameTemplate(
          this.props.item_request.ireq_id,
          this.props.patient_info,
          `F021-17_Wire_Selection_Guide${is_manual_upload ? '_Manual' : ''}.pdf`
        ),
      },
      bmf_pdf: {
        preset: 'pdf_viewer',
        header_text: `Adjusted Bracket Measurements Form - ${ireq_id}`,
        modal_size_class: 'modal-lg modal-lg-pdf',
        modal_body_class: 'modal-pdf',
        pdf_url: this.state.adjusted_bmf_file?.upload_data,
        pdf_date: this.state.adjusted_bmf_file?.created_date,
        close_btn_text: 'Close',
        original_filename: getPDFNameTemplate(
          this.props.item_request.ireq_id,
          this.props.patient_info,
          `F021-16_Adjusted_BMF${this.state.adjusted_bmf_file?.upload_data?.includes('manual') ? '_Manual' : ''}.pdf`
        ),
      },
    };

    if (modal in modals) {
      return (
        <Modal
          preset={'preset' in modals[modal] ? modal['preset'] : 'decision'}
          close_btn_text={'close_btn_text' in modals[modal] ? 'Close' : 'Cancel'}
          onConfirmButtonClick={() => {
            data.length > 0 ? that.wsRemove(data, raw_ireq_id) : that.wsAction(modal);
          }}
          onCloseButtonClick={this.clearModal}
          theme="bpp"
          {...modals[modal]}
        />
      );
    }

    return null;
  };

  isComplete = () => {
    const that = this;
    const ireq_status = that.props && that.props.item_request && that.props.item_request.ireq_status ? that.props.item_request.ireq_status : '';
    const marked_shipped =
      that.props && that.props.item_request && that.props.item_request.shipping_approved_ind ? that.props.item_request.shipping_approved_ind : false;
    return _.intersection([ireq_status], ['Item Request Cancelled', 'Item Request On Hold', 'Item Request Shipped']).length > 0 || marked_shipped;
  };

  displayComponentByStatus = () => {
    const { status, enabled_universal } = { ...this.state };

    const component = {
      'WS Loading': <Loading />,
      'WS Conversion': <Conversion {...this} />,
      'WS Conversion Succeeded': <ConversionComplete {...this} />,
      'WS Conversion Failed': <ConversionComplete {...this} />,
      'WS Override': <Override {...this} />,
      'WS Manual Process': <ConversionComplete {...this} manual_process="true" enabled_universal={enabled_universal} />,
      'WS Complete': <Conversion {...this} />,
    };

    return status in component ? (
      component[status]
    ) : (
      <div className="center-text">
        <div className="process-box-intro">
          <div className="process-box-content">Please contact your admin for assistants</div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.displayComponentByStatus()}
        {this.displayModal()}
        {this.state.refresh && <SessionExpire />}
      </>
    );
  }
}

export default WireSelection;
