import './thumbnail.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onDownload } from '../../common/functions';
import Lightbox from '../custom_lightbox/Lightbox';
import React, { Component } from 'react';
import ImageViewer, { initialState } from '../../doctor/components/record_viewer/image_viewer';
class ThumbnailViewer extends Component {
  state = {
    lightboxIsOpen: false,
    lightboxCurrent: 0,
    images: this.buildImages(),
  };

  /**
   * Builds a list of images base on files
   * @function
   * @returns {Object} The list of images
   */
  buildImages(loading = true) {
    return this.props.files.map((file) => ({
      id: file.id,
      created_date: file.created_date,
      file_type: file.file_type,
      filename: file.original_filename,
      src: this.isScans(file) && file.file_url ? file.file_url : this.getImageSrc(file),
      alt: this.isScans(file) ? file.upload_data : '',
      thumbnail: this.getImageSrc(file),
      caption: this.getImageCaption(file),
      is_scan: this.isScans(file),
      new: this.isNewUpload(file.created_date),
      loading: loading,
      record_state: this.getRecordState(file),
    }));
  }

  /**
   * Get a file's image src base on file type
   * @function
   * @param {Object} file - A single file object
   * @returns {String} The image src
   */
  getImageSrc(file) {
    const { file_type } = file;
    if (this.isModels(file)) {
      return this.props.multinail && this.props.multinail.includes('repaired')
        ? process.env.PUBLIC_URL + '/static/img/repaired.png'
        : process.env.PUBLIC_URL + '/static/img/model.png';
    }

    if ((file_type === 'photos' || file_type === 'xrays' || file_type === 'progress_records') && !file.file_url) {
      return process.env.PUBLIC_URL + '/static/img/no-image.png';
    }

    switch (file_type) {
      case 'upperscans':
        return process.env.PUBLIC_URL + '/static/img/upper.png';
      case 'lowerscans':
        return process.env.PUBLIC_URL + '/static/img/lower.png';
      default:
        return `/media/image/?file=${file.upload_data}&size=lrg`;
    }
  }

  /**
   * Check if file is a model or not
   * @function
   * @param {Object} file - A single file object
   * @returns {Boolean} Is model or not
   */
  isModels(file) {
    return file.file_type === 'multi_scans' || file.file_type === 'scans' || (file.upload_data && file.upload_data.includes('.stl'));
  }

  /**
   * Check if file is a scan or not
   * @function
   * @param {Object} file - A single file object
   * @returns {Boolean} Is scan or not
   */
  isScans(file) {
    return file.file_type === 'upperscans' || file.file_type === 'lowerscans' || this.isModels(file);
  }

  /**
   * Get a file's download caption
   * @function
   * @param {Object} file - A single file object
   * @returns {JSX} File's download caption
   */
  getImageCaption(file) {
    if (file.file_type === 'multi_scans') {
      return <p />;
    } else if (file.upload_data !== null && file.file_url) {
      return (
        <p>
          <a href={`/${file.upload_data}`} download onClick={onDownload}>
            <i className="fa fa-download" aria-hidden="true" /> Download Original File
          </a>
        </p>
      );
    } else {
      return <p />;
    }
  }

  /**
   * Closes the lightbox
   * @function
   */
  closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };

  /**
   * Go to the next image
   * @function
   */
  gotoNext = () => {
    const lightboxCurrent = Math.min(this.state.lightboxCurrent + 1, this.state.images.length - 1);
    this.setState({ lightboxCurrent: lightboxCurrent });
  };

  /**
   * Go to the previous image
   * @function
   */
  gotoPrevious = () => {
    const lightboxCurrent = Math.max(0, this.state.lightboxCurrent - 1);
    this.setState({ lightboxCurrent: lightboxCurrent });
  };

  /**
   * Check if file's date is new or not
   * @function
   * @param {Date} date - File's upload date
   * @returns {Boolean} Whether is new upload
   */
  isNewUpload(date) {
    let is_new = false;
    if (this.props.latest_status_date) {
      let latest_date = new Date(this.props.latest_status_date).getTime();
      let image_date = new Date(date).getTime();

      if (image_date - latest_date > 0) {
        this.props.isCheckingFiles != null ? (is_new = this.props.isCheckingFiles) : (is_new = true);
      }
    }

    return is_new;
  }

  /**
   * Render the JSX for the repair icon
   * @function
   * @param {Object} image - A single image object
   * @returns {JSX} The repair icon
   */
  createRemoveRepairIcon(image) {
    return (this.props.multinail === 'repaired_scans' && this.props.files.length === 1) ||
      (this.props.multinail === 'repaired_scans' && image.file_type === 'multi_scans') ? (
      <div
        className={'btn-clear remove-repaired-scans'}
        onClick={this.props.toggleRemoveModal}
        data-toggle="tooltip"
        data-placement="top"
        title={this.props.files.length === 1 ? '' : 'Remove Uploaded Scans'}
      >
        <i className="fa fa-trash-o" aria-hidden="true" />
      </div>
    ) : null;
  }

  /**
   * Get an image's css classnames
   * @function
   * @param {Object} image - A single image object
   * @returns {String} An image's classname
   */
  getImageClassName(image) {
    return image.loading ? 'small-img-window placeholder-block fs-exclude' : 'small-img-window fs-exclude';
  }

  /**
   * Handles clicking on images
   * @function
   * @param {Number} index - The index of image
   */
  onClickImage = (index) => {
    this.setState({ lightboxIsOpen: true, lightboxCurrent: index });
  };

  /**
   * Handles image loaded successfully
   * @function
   * @param {Number} index - The index of image
   */
  onImageLoadSuccess = (index) => {
    const newImages = this.state.images.map((image, i) => (i === index ? { ...image, loading: false } : image));
    this.setState({ images: newImages });
  };

  /**
   * Handles image loaded with error
   * @function
   * @param {Number} index - The index of image
   */
  onImageLoadError = (index) => {
    const imageNotFoundURL = process.env.PUBLIC_URL + '/static/img/no-image.png';
    const newImages = this.state.images.map((image, i) => (i === index ? { ...image, src: imageNotFoundURL, loading: false, caption: null } : image));
    this.setState({ images: newImages });
  };

  /**
   * Checks if to show the image's filename or not
   * @function
   * @param {Object} image - A single image object
   * @returns {Boolean} Show image's filename or not
   */
  showImageFileName(image) {
    return image.file_type !== 'upperscans' && image.file_type !== 'lowerscans' && image.file_type !== 'photos' && image.file_type !== 'xrays';
  }

  componentDidUpdate() {
    if (this.props.rebuild) {
      this.props.disabledRebuild && this.props.disabledRebuild();
      this.setState({ images: this.buildImages(false) });
    }
  }

  /**
   * Get File record state
   * @function
   * @param {Object} file - A single file object
   * @returns {Object} File record state in object format
   */
  getRecordState(file) {
    if (file) {
      if (file.record_state && typeof file.record_state === 'string') {
        return JSON.parse(file.record_state);
      } else if (file.record_state && typeof file.record_state === 'object') {
        return file.record_state;
      }
    }
    return initialState;
  }

  render() {
    const containerClasses = ['thumbnail-container'];
    if (this.props.fit) {
      containerClasses.push('thumbnail-container--fit');
    }
    if (this.props.recordScroll) {
      containerClasses.push('record-thumbnail-container');
    }
    const container = containerClasses.join(' ');

    return (
      <div className={container}>
        {this.props.has_setup ? (
          <div className="small-img-window small-img-window--btn fs-exclude" data-case_id={this.props.case_id} onClick={this.props.setupClick}>
            Smile Design Viewer <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
          </div>
        ) : null}

        {this.state.images.map((image, index) =>
          image.is_scan ? (
            <div key={index} className="small-img-window container-image fs-exclude" onClick={() => this.onClickImage(index)}>
              <img className="image-fixed-size small-img fs-exclude wide" src={image.thumbnail} alt="" />
              {this.showImageFileName(image) && <div className="image-text-centered">{image.filename}</div>}
              {image.new && <div className="image-new-indicator">New</div>}
              {this.createRemoveRepairIcon(image)}
            </div>
          ) : (
            <div key={index} className={this.getImageClassName(image)} onClick={() => this.onClickImage(index)}>
              <ImageViewer src={image.src} controlsDisabled={true} {...image.record_state} image={image} />
              {image.new && !image.loading && <div className="image-new-indicator">New</div>}
            </div>
          )
        )}

        <Lightbox
          images={this.state.images}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          currentImage={this.state.lightboxCurrent}
          onClose={this.closeLightbox}
        />
      </div>
    );
  }
}

export default ThumbnailViewer;
