// External Libs
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Internal Libs
import Modal from '../../modal/modal';
import { removeCaseIdInitialNumber } from '../../../common/functions';
import { userHasPermission } from '../../../common/permission';
import { UserPermissionsContext } from '../../../context/user_permission';

import * as nomenclature from '../../../common/nomenclature';

//Redux
import { getStage1Questions } from '../../../redux/reducers/bpp/production_tx_guide/stage_1';
import { getIfuState } from '../../../redux/reducers/ifu/ifu_state';
import { getIsHistoricalSingleToothIdb, getSingleToothIdbFinalFile } from '../../../redux/reducers/bpp/single_tooth_idb/single_tooth_idb';

/**
 * Displays label generation selection step
 * @component
 * @alias LabelSelection
 * @category BPP
 */
class LabelSelection extends Component {
  constructor(props) {
    super(props);

    const txplan_arch = this.props.treatment_arch ? this.props.treatment_arch.toUpperCase() : 'BOTH';
    const case_id = this.props.case_id;

    let state = {
      showGenerateModal: false,
      showOverrideModal: false,
      upper_smartwire_014_NiTi: !this.props.gen_2,
      upper_smartwire_016_NiTi: !this.props.gen_2,
      lower_smartwire_014_NiTi: !this.props.gen_2,
      lower_smartwire_016_NiTi: !this.props.gen_2,
      lower_smartwire_016_straight_NiTi: !this.props.gen_2,
      upper_idb: true,
      lower_idb: true,
      upper_de_idb: true,
      lower_de_idb: true,
      replacement_brackets: !this.props.gen_2,
      content_labels: !this.props.gen_2,
      udi_label: true,
      txplan_arch: txplan_arch,
      upper_smartwire_1: this.props.gen_2,
      upper_smartwire_2: this.props.gen_2,
      upper_smartwire_3: false,
      lower_smartwire_1: this.props.gen_2,
      lower_smartwire_2: this.props.gen_2,
      lower_smartwire_3: false,
      lower_anterior_bracket: this.props.gen_2,
      upper_anterior_bracket: this.props.gen_2,
      small_posterior_bracket: this.props.gen_2,
      large_posterior_bracket: this.props.gen_2,
      outer_patient_label: this.props.gen_2,
      upper_single_tooth_idb: false,
      lower_single_tooth_idb: false,
      bond_for_later: false,
      manifest: [],
    };

    // Set label selections to be the same as previous selection (if applicable)
    //
    let logs = this.props.status_log.filter((log) => {
      return log.status_code === 'Label Generation Process';
    });

    if (logs.length > 0 && logs[logs.length - 1].text) {
      let status_comment = JSON.parse(logs[logs.length - 1].text);
      status_comment = status_comment.manifest;
      state.manifest = status_comment;

      const label_items = [
        'upper_smartwire_014_NiTi',
        'upper_smartwire_016_NiTi',
        'lower_smartwire_014_NiTi',
        'lower_smartwire_016_NiTi',
        'lower_smartwire_016_straight_NiTi',
        'upper_idb',
        'lower_idb',
        'upper_de_idb',
        'lower_de_idb',
        'replacement_brackets',
        'content_labels',
        'udi_label',
        'upper_smartwire_1',
        'upper_smartwire_2',
        'upper_smartwire_3',
        'lower_smartwire_1',
        'lower_smartwire_2',
        'lower_smartwire_3',
        'lower_anterior_bracket',
        'upper_anterior_bracket',
        'small_posterior_bracket',
        'large_posterior_bracket',
        'outer_patient_label',
        'upper_single_tooth_idb',
        'lower_single_tooth_idb',
        'bond_for_later',
      ];

      for (let label_item of label_items) {
        state[label_item] = status_comment.includes(label_item);
      }
    } else {
      if (this.props.gen_2) {
        if (this.props.case_id.includes('-DE')) {
          state['udi_label'] = true;
          state['outer_patient_label'] = false;
          state['upper_idb'] = false;
          state['lower_idb'] = false;
          state['bond_for_later'] = false;

          const wires = this.props.stage_1_questions.wire_selections;
          if (wires.indexOf(nomenclature.UPPER_DE_SMARTWIRE_1) === -1) {
            state['upper_smartwire_1'] = false;
          }
          if (wires.indexOf(nomenclature.UPPER_DE_SMARTWIRE_2) === -1) {
            state['upper_smartwire_2'] = false;
          }
          if (wires.indexOf(nomenclature.UPPER_DE_SMARTWIRE_3) === -1) {
            state['upper_smartwire_3'] = false;
          }
          if (wires.indexOf(nomenclature.LOWER_DE_SMARTWIRE_1) === -1) {
            state['lower_smartwire_1'] = false;
          }
          if (wires.indexOf(nomenclature.LOWER_DE_SMARTWIRE_2) === -1) {
            state['lower_smartwire_2'] = false;
          }
          if (wires.indexOf(nomenclature.LOWER_DE_SMARTWIRE_3) === -1) {
            state['lower_smartwire_3'] = false;
          }
          if (!this.props.stage_1_questions.upper_idb_tray) {
            state['upper_de_idb'] = false;
          }
          if (!this.props.stage_1_questions.lower_idb_tray) {
            state['lower_de_idb'] = false;
          }
        } else {
          state['upper_de_idb'] = false;
          state['lower_de_idb'] = false;
          switch (txplan_arch) {
            case 'UPPER':
              state['lower_idb'] = false;
              state['lower_smartwire_1'] = false;
              state['lower_smartwire_2'] = false;
              state['lower_smartwire_3'] = false;
              break;

            case 'LOWER':
              state['upper_idb'] = false;
              state['upper_smartwire_1'] = false;
              state['upper_smartwire_2'] = false;
              state['upper_smartwire_3'] = false;
              break;

            default:
          }
        }

        const bond_for_later_counts = this.getBondForLaterBrackets();
        if (this.props.isHistoricalSingleToothIdb && bond_for_later_counts) {
          for (const bracket in bond_for_later_counts) {
            if (bond_for_later_counts[bracket] === 0) {
              state[`${bracket}_bracket`] = false;
            }
          }
        } else {
          state['lower_anterior_bracket'] = false;
          state['upper_anterior_bracket'] = false;
          state['small_posterior_bracket'] = false;
          state['large_posterior_bracket'] = false;
        }
      } else {
        switch (txplan_arch) {
          case 'UPPER':
            state['lower_smartwire_014_NiTi'] = false;
            state['lower_smartwire_016_NiTi'] = false;
            state['lower_smartwire_016_straight_NiTi'] = false;
            state['lower_idb'] = false;
            break;

          case 'LOWER':
            state['upper_smartwire_014_NiTi'] = false;
            state['upper_smartwire_016_NiTi'] = false;
            state['upper_idb'] = false;
            break;

          default:
        }

        if (case_id.indexOf('-') >= 0) {
          state['replacement_brackets'] = false;
          state['upper_smartwire_014_NiTi'] = false;
          state['lower_smartwire_014_NiTi'] = false;
          if (!this.props.stage_1_questions.upper_idb_tray) {
            state['upper_idb'] = false;
          }
          if (!this.props.stage_1_questions.lower_idb_tray) {
            state['lower_idb'] = false;
          }
        }
      }
    }
    this.state = state;
  }

  /**
   * Helper function for readability.
   * Parses the selected teeth and sets the state for single tooth idb
   * @param {Array} selectedTeeth Array of teeth - Strings
   */
  parseTeeth = (selectedTeeth) => {
    let upper = false;
    let lower = false;
    for (let tooth of selectedTeeth) {
      if (tooth.includes('UR') || tooth.includes('UL')) {
        upper = true;
      }
      if (tooth.includes('LR') || tooth.includes('LL')) {
        lower = true;
      }
    }
    this.setState({ upper_single_tooth_idb: upper, lower_single_tooth_idb: lower });
  };

  /**
   * Checks Stage 1 Selections to set Single Tooth Label State
   */
  getSingleToothSelection = () => {
    if (!this.state.manifest.length && !this.props.isHistoricalSingleToothIdb && this.props.single_tooth_idb_final_file) {
      const isDe = this.props.case_id.includes('-DE');
      const deSelections = ['repositioned', 'new', 'arch'];
      const intialSelections = ['not_included', 'offset'];
      const selections = isDe ? deSelections : intialSelections;
      const selectedTeeth = this.props.stage_1_questions?.stage_1_selected_teeth;
      const sidbTeeth = [];
      for (let selectionType in selectedTeeth) {
        if (selections.includes(selectionType)) {
          sidbTeeth.push(...selectedTeeth[selectionType]);
        }
      }
      this.parseTeeth(sidbTeeth);
    }
  };

  componentDidMount = () => {
    this.getSingleToothSelection();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.stage_1_questions !== this.props.stage_1_questions && !this.props.gen_2) {
      this.setState({ upper_idb: this.props.stage_1_questions.upper_idb_tray });
      this.setState({ lower_idb: this.props.stage_1_questions.lower_idb_tray });
    }
  };

  toggleGenerateModal = () => {
    this.setState({ showGenerateModal: !this.state.showGenerateModal });
  };

  toggleOverrideModal = () => {
    this.setState({ showOverrideModal: !this.state.showOverrideModal });
  };

  enterReviewProcess = () => {
    this.toggleGenerateModal();

    let label_options = [];

    if (this.state['upper_smartwire_014_NiTi']) label_options.push('upper_smartwire_014_NiTi');
    if (this.state['upper_smartwire_016_NiTi']) label_options.push('upper_smartwire_016_NiTi');
    if (this.state['lower_smartwire_014_NiTi']) label_options.push('lower_smartwire_014_NiTi');
    if (this.state['lower_smartwire_016_NiTi']) label_options.push('lower_smartwire_016_NiTi');
    if (this.state['lower_smartwire_016_straight_NiTi']) label_options.push('lower_smartwire_016_straight_NiTi');
    if (this.state['upper_idb']) label_options.push('upper_idb');
    if (this.state['lower_idb']) label_options.push('lower_idb');
    if (this.state['upper_de_idb']) label_options.push('upper_de_idb');
    if (this.state['lower_de_idb']) label_options.push('lower_de_idb');
    if (this.state['replacement_brackets']) label_options.push('replacement_brackets');
    if (this.state['content_labels']) label_options.push('content_labels');
    if (this.state['udi_label']) label_options.push('udi_label');
    if (this.state['upper_smartwire_1']) label_options.push('upper_smartwire_1');
    if (this.state['upper_smartwire_2']) label_options.push('upper_smartwire_2');
    if (this.state['upper_smartwire_3']) label_options.push('upper_smartwire_3');
    if (this.state['lower_smartwire_1']) label_options.push('lower_smartwire_1');
    if (this.state['lower_smartwire_2']) label_options.push('lower_smartwire_2');
    if (this.state['lower_smartwire_3']) label_options.push('lower_smartwire_3');
    if (this.state['lower_anterior_bracket']) label_options.push('lower_anterior_bracket');
    if (this.state['upper_anterior_bracket']) label_options.push('upper_anterior_bracket');
    if (this.state['small_posterior_bracket']) label_options.push('small_posterior_bracket');
    if (this.state['large_posterior_bracket']) label_options.push('large_posterior_bracket');
    if (this.state['outer_patient_label']) label_options.push('outer_patient_label');
    if (this.state['upper_single_tooth_idb']) label_options.push('upper_single_tooth_idb');
    if (this.state['lower_single_tooth_idb']) label_options.push('lower_single_tooth_idb');
    if (this.state['bond_for_later']) label_options.push('bond_for_later');

    if (!this.props.gen_2) {
      label_options.push(this.state.txplan_arch);
    }

    let label_request = {
      label_options: label_options,
    };

    this.props.startLabelGeneration(label_request);
  };

  labelsSelected = () => {
    if (this.props.gen_2) {
      if (
        this.state['upper_idb'] ||
        this.state['lower_idb'] ||
        this.state['upper_de_idb'] ||
        this.state['lower_de_idb'] ||
        this.state['udi_label'] ||
        this.state['upper_smartwire_1'] ||
        this.state['upper_smartwire_2'] ||
        this.state['upper_smartwire_3'] ||
        this.state['lower_smartwire_1'] ||
        this.state['lower_smartwire_2'] ||
        this.state['lower_smartwire_3'] ||
        this.state['lower_anterior_bracket'] ||
        this.state['upper_anterior_bracket'] ||
        this.state['small_posterior_bracket'] ||
        this.state['large_posterior_bracket'] ||
        this.state['outer_patient_label'] ||
        this.state['upper_single_tooth_idb'] ||
        this.state['lower_single_tooth_idb'] ||
        this.state['bond_for_later']
      ) {
        return true;
      }
      return false;
    }

    if (
      this.state['upper_smartwire_014_NiTi'] ||
      this.state['upper_smartwire_016_NiTi'] ||
      this.state['lower_smartwire_014_NiTi'] ||
      this.state['lower_smartwire_016_NiTi'] ||
      this.state['lower_smartwire_016_straight_NiTi'] ||
      this.state['upper_idb'] ||
      this.state['lower_idb'] ||
      this.state['replacement_brackets'] ||
      this.state['content_labels'] ||
      this.state['udi_label']
    ) {
      return true;
    }
    return false;
  };

  generateModal = () => {
    const header_text = `Generate Labels - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to proceed with label generation?"
        confirm_btn_text="Generate"
        onConfirmButtonClick={this.enterReviewProcess}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelGenerateModal}
      />
    );
  };

  overrideModal = () => {
    const header_text = `Override Process - ${removeCaseIdInitialNumber(this.props.case_id)}`;

    return (
      <Modal
        theme="bpp"
        preset="decision"
        header_text={header_text}
        message_text="Are you sure you want to override process?"
        confirm_btn_text="Override"
        onConfirmButtonClick={this.props.overrideLabelGeneration}
        close_btn_text="Cancel"
        onCloseButtonClick={this.onCancelOverrideModal}
      />
    );
  };

  onCancelGenerateModal = (event) => {
    this.toggleGenerateModal();
  };

  onCancelOverrideModal = (event) => {
    this.toggleOverrideModal();
  };

  toggleCheckbox = (event) => {
    const state_name = event.target.id;
    let state = {};
    state[state_name] = !this.state[state_name];

    this.setState(state);
  };

  getGen2LabelSelectionsCase = () => {
    let labels = [
      { label: nomenclature.UPPER_SMARTWIRE_1, state: 'upper_smartwire_1' },
      { label: nomenclature.UPPER_SMARTWIRE_2, state: 'upper_smartwire_2' },
      { label: nomenclature.LOWER_SMARTWIRE_1, state: 'lower_smartwire_1' },
      { label: nomenclature.LOWER_SMARTWIRE_2, state: 'lower_smartwire_2' },
      { label: nomenclature.UPPER_IDB_TRAY, state: 'upper_idb' },
      { label: nomenclature.LOWER_IDB_TRAY, state: 'lower_idb' },
      { label: nomenclature.UPPER_ANTERIOR_BRACKET, state: 'upper_anterior_bracket' },
      { label: nomenclature.LOWER_ANTERIOR_BRACKET, state: 'lower_anterior_bracket' },
      { label: nomenclature.SMALL_POSTERIOR_BRACKET, state: 'small_posterior_bracket' },
      { label: nomenclature.LARGE_POSTERIOR_BRACKET, state: 'large_posterior_bracket' },
      { label: nomenclature.BOND_FOR_LATER, state: 'bond_for_later' },
      { label: 'Outer Patient Label', state: 'outer_patient_label' },
      { label: 'UDI Label', state: 'udi_label' },
    ];

    if (!this.props.isHistoricalSingleToothIdb) {
      labels.push({ label: nomenclature.UPPER_SINGLE_TOOTH_IDB_TRAY, state: 'upper_single_tooth_idb' });
      labels.push({ label: nomenclature.LOWER_SINGLE_TOOTH_IDB_TRAY, state: 'lower_single_tooth_idb' });
    }

    return labels;
  };

  getGen2LabelSelectionsDE = () => {
    let labels = [
      { label: nomenclature.UPPER_DE_SMARTWIRE_1, state: 'upper_smartwire_1' },
      { label: nomenclature.UPPER_DE_SMARTWIRE_2, state: 'upper_smartwire_2' },
      { label: nomenclature.LOWER_DE_SMARTWIRE_1, state: 'lower_smartwire_1' },
      { label: nomenclature.LOWER_DE_SMARTWIRE_2, state: 'lower_smartwire_2' },
      { label: nomenclature.UPPER_DE_IDB_TRAY, state: 'upper_de_idb' },
      { label: nomenclature.LOWER_DE_IDB_TRAY, state: 'lower_de_idb' },
      { label: nomenclature.LOWER_ANTERIOR_BRACKET, state: 'lower_anterior_bracket' },
      { label: nomenclature.UPPER_ANTERIOR_BRACKET, state: 'upper_anterior_bracket' },
      { label: nomenclature.SMALL_POSTERIOR_BRACKET, state: 'small_posterior_bracket' },
      { label: nomenclature.LARGE_POSTERIOR_BRACKET, state: 'large_posterior_bracket' },
      { label: 'UDI Label', state: 'udi_label' },
    ];

    if (!this.props.isHistoricalSingleToothIdb) {
      labels.push({ label: nomenclature.UPPER_SINGLE_TOOTH_IDB_TRAY, state: 'upper_single_tooth_idb' });
      labels.push({ label: nomenclature.LOWER_SINGLE_TOOTH_IDB_TRAY, state: 'lower_single_tooth_idb' });
    }

    return labels;
  };

  getBondForLaterBrackets() {
    if (this.props.ifu_state && this.props.ifu_state.txinstruction_status_comment) {
      const status_comment = JSON.parse(this.props.ifu_state.txinstruction_status_comment);
      if ('bond_for_later_counts' in status_comment) {
        return status_comment.bond_for_later_counts;
      }
    }
    return null;
  }

  displaySelections(selections) {
    return (
      <>
        {selections.map((selection, index) => {
          return (
            <div key={index} className="col-lg-6">
              <input
                id={selection.state}
                className={selection.hide ? 'filter-hidden' : 'label-checkbox pointer'}
                type="checkbox"
                name="case_labels"
                value={this.state[selection.state]}
                checked={this.state[selection.state]}
                onChange={this.toggleCheckbox}
              />
              <label className={selection.hide ? 'filter-hidden' : 'pointer'} htmlFor={selection.state}>
                {' '}
                {selection.label}
              </label>
            </div>
          );
        })}
      </>
    );
  }

  displayGen2Selections(selections) {
    const max_labels_per_col = 6;
    const col_lg_6 = selections.length <= max_labels_per_col * 2;
    return (
      <>
        <div className={`col-lg-${col_lg_6 ? '6' : '4'}`}>{this.displayGen2SelectionColumn(selections, 0, max_labels_per_col)}</div>
        <div className={`col-lg-${col_lg_6 ? '6' : '4'}`}>{this.displayGen2SelectionColumn(selections, max_labels_per_col, max_labels_per_col * 2)}</div>
        {!col_lg_6 && <div className="col-lg-4">{this.displayGen2SelectionColumn(selections, max_labels_per_col * 2, selections.length)}</div>}
      </>
    );
  }

  displayGen2SelectionColumn(selections, start_pos, end_pos) {
    const column_selections = selections.slice(start_pos, end_pos);
    return (
      <>
        {column_selections.map((selection, index) => {
          return (
            <div key={index}>
              <input
                id={selection.state}
                className={selection.hide ? 'filter-hidden' : 'label-checkbox pointer'}
                type="checkbox"
                name="case_labels"
                value={this.state[selection.state]}
                checked={this.state[selection.state]}
                onChange={this.toggleCheckbox}
              />
              <label className={selection.hide ? 'filter-hidden' : 'pointer'} htmlFor={selection.state}>
                {' '}
                {selection.label}
              </label>
            </div>
          );
        })}
      </>
    );
  }

  render() {
    const isDE = this.props.case_id.indexOf('-DE') >= 0 || this.props.case_id.indexOf('-R') >= 0;
    let selections = this.props.gen_2
      ? this.props.case_id.includes('-DE')
        ? this.getGen2LabelSelectionsDE()
        : this.getGen2LabelSelectionsCase()
      : [
          { label: isDE ? nomenclature.BPP_UPPER_DE_SMARTWIRE_1 : nomenclature.BPP_UPPER_SMARTWIRE_1, state: 'upper_smartwire_014_NiTi' },
          { label: isDE ? nomenclature.UPPER_DE_IDB_TRAY : nomenclature.UPPER_IDB_TRAY, state: 'upper_idb' },
          { label: isDE ? nomenclature.BPP_UPPER_DE_SMARTWIRE_2 : nomenclature.BPP_UPPER_SMARTWIRE_2, state: 'upper_smartwire_016_NiTi' },
          { label: isDE ? nomenclature.LOWER_DE_IDB_TRAY : nomenclature.LOWER_IDB_TRAY, state: 'lower_idb' },
          { label: isDE ? nomenclature.BPP_LOWER_DE_SMARTWIRE_1 : nomenclature.BPP_LOWER_SMARTWIRE_1, state: 'lower_smartwire_014_NiTi' },
          { label: nomenclature.REPLACEMENT_BRACKETS, state: 'replacement_brackets' },
          { label: isDE ? nomenclature.BPP_LOWER_DE_SMARTWIRE_2 : nomenclature.BPP_LOWER_SMARTWIRE_2, state: 'lower_smartwire_016_NiTi' },
          { label: 'Box Content Labels', state: 'content_labels' },
          { label: isDE ? nomenclature.BPP_LOWER_DE_SMARTWIRE_2A : nomenclature.BPP_LOWER_SMARTWIRE_2A, state: 'lower_smartwire_016_straight_NiTi' },
          { label: 'UDI Label', state: 'udi_label' },
        ];
    return (
      <UserPermissionsContext.Consumer>
        {(user_roles_and_permissions) => {
          return userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions) ? (
            <>
              <div className="process-box-intro">
                <div className="process-box-title">Label Selection</div>
                <div>
                  The required labels to be printed have been preselected. Upon selection completion, click <span className="bold-text">Generate</span> to begin
                  the label generation process.
                </div>
              </div>

              <div className={`row${this.props.gen_2 ? ' label-selection' : ''}`}>
                {this.props.gen_2 ? this.displayGen2Selections(selections) : this.displaySelections(selections)}
              </div>
              <div className="button-panel">
                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('CASE_LABEL_GENERATION', user_roles_and_permissions.permissions) && this.labelsSelected() ? (
                      <button type="button" className="btn btn-light" onClick={this.toggleGenerateModal}>
                        Generate
                      </button>
                    ) : (
                      <button type="button" className="btn btn-light" disabled={true}>
                        Generate
                      </button>
                    );
                  }}
                </UserPermissionsContext.Consumer>

                <UserPermissionsContext.Consumer>
                  {(user_roles_and_permissions) => {
                    return userHasPermission('CASE_LABEL_OVERRIDE', user_roles_and_permissions.permissions) ? (
                      <button type="button" className="btn btn-light" onClick={this.toggleOverrideModal}>
                        Override
                      </button>
                    ) : null;
                  }}
                </UserPermissionsContext.Consumer>
              </div>

              {this.state.showGenerateModal ? this.generateModal() : null}
              {this.state.showOverrideModal ? this.overrideModal() : null}
            </>
          ) : (
            <div className="process-box-intro">
              <div className={`center-text content-center${!this.props.gen_2 ? ' margin-top-30' : ''}`}>Label generation process in progress...</div>
            </div>
          );
        }}
      </UserPermissionsContext.Consumer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stage_1_questions: getStage1Questions(state),
    ifu_state: getIfuState(state),
    single_tooth_idb_final_file: getSingleToothIdbFinalFile(state),
    isHistoricalSingleToothIdb: getIsHistoricalSingleToothIdb(state),
  };
};

export default connect(mapStateToProps, null)(LabelSelection);
