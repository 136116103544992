/**
 * File: de_form2.js - Contains the logic for submitting a digital
 * enhancenment
 * Copyright: (c) Copyright December 2019 by InBrace
 * Authors: David Vu
 * Project: InBrace Provider/Business Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// External Libs
import _ from 'lodash';
import Moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isValidDate } from '../../common/helpers';
import * as nomenclature from '../../common/nomenclature';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction } from '../../redux/actions/record_viewer/record_viewer';
import { onImageToolAction } from '../../common/functions';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import AlertContainer from '../components/container/alert_container';
import CardContainer from '../components/container/card_container';
import InputDate from '../components/inputs/input_date';
import TextArea from '../components/inputs/text_area';
import TextBox from '../components/inputs/text_box';
import RecordViewer from '../components/record_viewer/record_viewer';
import { getMinDate, getMaxDate } from '../../common/helpers';

/**
 * Renders a form with a text area input field.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.field - The name of the field.
 * @param {string} props.heading - The heading to display above the text area.
 * @param {string} props.dataTestId - The data-testid attribute for the form group.
 * @param {number} [props.rows] - The number of rows to display in the text area (default is 4).
 * @param {string} props.description - The placeholder text for the text area.
 * @param {string} props.value - The value of the text area.
 * @param {function} props.onChange - The function to call when the text area value changes.
 * @param {function} props.onFocus - The function to call when the text area receives focus.
 * @returns {JSX.Element} A React Fragment containing the text area form.
 */
function TextAreaForm(props) {
  return (
    <React.Fragment>
      <h3 id={props.field} className="tx-heading">
        {props.heading}
      </h3>
      <div className="form-group" data-testid={props.dataTestId}>
        <TextArea
          id={props.field + '_textarea'}
          rows={props.rows ? props.rows : '4'}
          placeholder={props.description}
          data-field={props.field}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
        />
      </div>
    </React.Fragment>
  );
}

/**
 * Renders a date input area with a title and warning message if the date is invalid.
 * @param {Object} props - The component props.
 * @param {string} props.dataTestId - The data-testid attribute for testing purposes.
 * @param {string} props.field - The name of the date field.
 * @param {string} props.heading - The title of the date input area.
 * @param {function} props.onChange - The function to call when the date input changes.
 * @param {string} props.value - The value of the date input.
 * @param {function} props.onFocus - The function to call when the date input is focused.
 * @param {boolean} props.warning - Whether the date input is invalid.
 * @returns {JSX.Element} - The rendered date input area.
 */
function DateArea(props) {
  return (
    <div className="question-box" data-testid={props.dataTestId}>
      <span className={'title emphasis' + (props.warning ? ' title--warning' : '')} id={props.field}>
        {props.heading}
      </span>
      <CardContainer type="form">
        <InputDate
          id={props.field + '_input'}
          data-field={props.field}
          onChange={props.onChange}
          value={props.value}
          onFocus={props.onFocus}
          className={'de2-date' + (props.warning ? ' warning-border' : '')}
          max={getMaxDate()}
          min={getMinDate()}
        />
        {props.warning && (
          <span id={props.field + '_warning'} className="title title--warning emphasis">
            Invalid Date
          </span>
        )}
      </CardContainer>
    </div>
  );
}

/**
 * Renders a teeth selector chart for a dental form
 * @function
 * @param {Object} props - Component props
 * @param {boolean} props.hasUpper - Whether the patient has upper teeth
 * @param {boolean} props.hasLower - Whether the patient has lower teeth
 * @param {Array} props.disabledTeeth - Array of teeth to disable
 * @param {Array} props.teeth - Array of selected teeth
 * @param {string} props.field - Name of the form field
 * @param {Function} props.onClick - Function to call when a tooth is clicked
 * @param {string} props.heading - Heading for the teeth selector chart
 * @param {string} props.sub_heading - Subheading for the teeth selector chart
 * @param {string} props.warning - Warning message to display
 * @param {string} props.dataTestId - Test ID for the teeth selector chart
 * @return {JSX.Element} - Teeth selector chart component
 */
function TeethArea(props) {
  const isInArray = (teeth, teethSet) => {
    return _.intersection(teethSet, teeth).length > 0;
  };

  let disabledTeeth = ['UR8', 'UL8', 'LR8', 'LL8'];
  const UR = ['UR8', 'UR7', 'UR6', 'UR5', 'UR4', 'UR3', 'UR2', 'UR1'];
  const UL = ['UL1', 'UL2', 'UL3', 'UL4', 'UL5', 'UL6', 'UL7', 'UL8'];
  const LR = ['LR8', 'LR7', 'LR6', 'LR5', 'LR4', 'LR3', 'LR2', 'LR1'];
  const LL = ['LL1', 'LL2', 'LL3', 'LL4', 'LL5', 'LL6', 'LL7', 'LL8'];

  if (!props.hasUpper) {
    disabledTeeth = disabledTeeth.concat(UR.concat(UL));
  } else if (!props.hasLower) {
    disabledTeeth = disabledTeeth.concat(LR.concat(LL));
  }

  if (props.disabledTeeth) {
    disabledTeeth = disabledTeeth.concat(props.disabledTeeth);
  }

  /**
   * Constructs teeth selector chart
   * @function
   * @param {Array} teethSet - Set of teeth to display
   * @return {Array} - Array of JSX elements for each tooth
   */
  const teethArray = (teethSet) => {
    return teethSet.map((teeth, ind) => {
      if (isInArray([teeth], disabledTeeth)) {
        return (
          <button key={ind} type="button" className="btn-ib btn-ib--disabled">
            {teeth}
          </button>
        );
      }

      return (
        <button
          key={ind}
          type="button"
          className={isInArray([teeth], props.teeth) ? 'btn-ib btn-ib--active' : 'btn-ib'}
          data-field={props.field}
          onClick={props.onClick}
          value={teeth}
        >
          {teeth}
        </button>
      );
    });
  };

  return (
    <div className="question-box" data-testid={props.dataTestId}>
      <span id={props.field} className="title emphasis">
        {props.heading}
      </span>
      <CardContainer type="form">
        {props.sub_heading ? props.sub_heading : null}
        <div className="teeth-selector">
          <div className="row">
            <div className="col-md-6">{teethArray(UR)}</div>
            <div className="col-md-6">{teethArray(UL)}</div>
          </div>
          <div className="row">
            <div className="col-md-6">{teethArray(LR)}</div>
            <div className="col-md-6">{teethArray(LL)}</div>
          </div>
        </div>
        {props.warning ? props.warning : null}
      </CardContainer>
    </div>
  );
}

/**
 * Renders a radio button with a label.
 * @param {Object} props - The component props.
 * @param {string} props.dataTestId - The data-testid attribute for testing purposes.
 * @param {string} props.field - The name of the radio button group.
 * @param {string} props.value - The value of the radio button.
 * @param {string} props.label - The label for the radio button.
 * @param {function} props.onChange - The function to call when the radio button is changed.
 * @param {function} props.onFocus - The function to call when the radio button is focused.
 * @param {string} props.selection - The currently selected radio button value.
 * @returns {JSX.Element} - The rendered radio button component.
 */
function RadioArea(props) {
  return (
    <div className="ib-radio" data-testid={props.dataTestId}>
      <label className="form-check-label">
        <input
          id={props.field + '_' + props.value}
          className="form-check-input"
          type="radio"
          name={props.field}
          data-field={props.field}
          value={props.value}
          onChange={props.onChange}
          onFocus={props.onFocus}
          defaultChecked={props.value === props.selection}
        />{' '}
        {props.label}
      </label>
    </div>
  );
}

/**
 * Renders a component with a date input and a warning message if the date is invalid.
 * @param {Object} props - The props object containing the following properties:
 * @param {string} props.dataTestId - The data-testid attribute for testing purposes.
 * @param {string} props.field - The name of the field.
 * @param {string} props.value - The value of the date input.
 * @param {function} props.onChange - The function to call when the date input changes.
 * @param {function} props.onFocus - The function to call when the date input is focused.
 * @param {boolean} props.warning - Whether the date is invalid or not.
 * @returns {JSX.Element} - The JSX element representing the component.
 */
function RadioDate(props) {
  return (
    <div className="inline-date-container" data-testid={props.dataTestId}>
      <span className={props.warning ? 'inline-date-container--warning' : ''}>Date of wire insertion:</span>
      <TextBox
        id={props.field + '_input'}
        type="date"
        data-field={props.field}
        value={props.value}
        onChange={props.onChange}
        onFocus={props.onFocus}
        className={props.warning ? 'warning-border' : ''}
        max={getMaxDate()}
        min={getMinDate()}
      />
      {props.warning && <span className="title title--warning emphasis">Invalid Date</span>}
    </div>
  );
}

/**
 * Renders a group of radio buttons with optional date input fields.
 * @param {Object} props - The component props.
 * @param {Array} props.radios - An array of radio button objects.
 * @param {string} props.dataTestIdPrefix - A prefix string for the data test IDs.
 * @returns {JSX.Element} - A React Fragment containing the radio buttons and date inputs.
 */
function RadioAreaWithDate(props) {
  return props.radios.map((radio, ind) => {
    return (
      <React.Fragment key={ind}>
        <RadioArea
          label={radio.label}
          field={radio.field}
          value={radio.value}
          selection={radio.selection()}
          onChange={radio.onChange}
          onFocus={radio.onFocus}
          dataTestId={`${props.dataTestIdPrefix}_${radio.value}`}
        />
        {radio.date && radio.selection() === radio.value ? (
          <RadioDate field={radio.date_field} value={radio.date_value()} onChange={radio.onChange} onFocus={radio.onFocus} warning={radio.warning()} />
        ) : null}
      </React.Fragment>
    );
  });
}

class DigitalEnhancementForm2 extends React.Component {
  componentDidMount() {
    this.buildImageRecords();
  }

  componentDidUpdate(prevProps) {
    if (this.props.photoUpload !== prevProps.photoUpload || this.props.xrayUpload !== prevProps.xrayUpload || this.props.scanUpload !== prevProps.scanUpload) {
      this.buildImageRecords();
    }
  }

  buildImageRecords() {
    const {
      photoUpload,
      xrayUpload,
      scanUpload,
      // iTero integration
      uploadMethod,
      selectedIteroScanRecords,
    } = this.props;
    const records = [];
    if (photoUpload) records.push(...photoUpload);
    if (xrayUpload) records.push(...xrayUpload);

    // iTero integration
    if (uploadMethod === 'itero' && selectedIteroScanRecords) {
      records.push(...selectedIteroScanRecords);
    } else if (scanUpload) {
      records.push(...scanUpload);
    }

    const options = { caseType: 'complete' };
    this.props.buildRecordStates(records, options);
  }

  getSpacing = (position, spacing_chart) => {
    return spacing_chart && spacing_chart[position] !== '' ? spacing_chart[position] : '';
  };

  getSpacingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
  upper_smartwire_field = 'de2_upper_smartwire';
  lower_smartwire_field = 'de2_lower_smartwire';

  upper_smartwire = [
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.upper_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'de',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.upper_smartwire_field,
      value: 'none',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  lower_smartwire = [
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.lower_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2A}</span>,
      field: this.lower_smartwire_field,
      value: 'stage2a',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'de',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2A}</span>,
      field: this.lower_smartwire_field,
      value: 'de_16_straight',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.lower_smartwire_field,
      value: 'none',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  upper_smartwire_gen2 = [
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.upper_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_3}</span>,
      field: this.upper_smartwire_field,
      value: 'stage3',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.upper_smartwire_field,
      value: 'de',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_3}</span>,
      field: this.upper_smartwire_field,
      value: 'de3',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.upper_smartwire_field + '_date',
      date_value: () => this.props.de2_upper_smartwire_date,
      warning: () => !isValidDate(this.props.de2_upper_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.upper_smartwire_field,
      value: 'none',
      selection: () => this.props.de2_upper_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  lower_smartwire_gen2 = [
    {
      label: <span>{nomenclature.SMARTWIRE_1}</span>,
      field: this.lower_smartwire_field,
      value: 'stage1',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'stage2',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.SMARTWIRE_3}</span>,
      field: this.lower_smartwire_field,
      value: 'stage3',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_2}</span>,
      field: this.lower_smartwire_field,
      value: 'de',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>{nomenclature.DE_SMARTWIRE_3}</span>,
      field: this.lower_smartwire_field,
      value: 'de3',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: true,
      date_field: this.lower_smartwire_field + '_date',
      date_value: () => this.props.de2_lower_smartwire_date,
      warning: () => !isValidDate(this.props.de2_lower_smartwire_date),
    },
    {
      label: <span>None</span>,
      field: this.lower_smartwire_field,
      value: 'none',
      selection: () => this.props.de2_lower_smartwire,
      onChange: this.props.onInputChange_de2,
      onFocus: this.props.onFocus,
      date: false,
      warning: () => false,
    },
  ];

  isDe = () => {
    return this.props && this.props.de2_prev_case_info && this.props.de2_prev_case_info.case_id && this.props.de2_prev_case_info.case_id.indexOf('-') >= 0;
  };

  getCaseType = () => {
    return this.isDe() ? 'most recent Digital Enhancement' : 'initial case';
  };

  getRestrictionMsg = () => {
    let result = '';

    if (
      this.props.de2_prev_case_info &&
      this.props.de2_prev_case_info.exist &&
      _.difference(this.props.de2_teeth_restriction, this.props.de2_prev_case_info.teeth_restriction).length === 0 &&
      _.difference(this.props.de2_prev_case_info.teeth_restriction, this.props.de2_teeth_restriction).length === 0
    ) {
      result = 'Teeth indicated not to be moved from your ' + this.getCaseType() + ' have been preselected for you';
    }

    return result;
  };

  hasLower = () => {
    return this.props.de2_arch_to_treat === 'both' || this.props.de2_arch_to_treat === 'lower';
  };

  hasUpper = () => {
    return this.props.de2_arch_to_treat === 'both' || this.props.de2_arch_to_treat === 'upper';
  };

  hasByPassCondition = () => {
    return (
      (this.props.de2_arch_to_treat === 'upper' && this.props.de2_arch_opposing_treat !== 'current') ||
      (this.props.de2_arch_to_treat === 'lower' && this.props.de2_arch_opposing_treat !== 'current')
    );
  };

  oneArchIsBeingDisplayed = () => {
    return this.props.de2_arch_to_treat !== 'both' && this.props.de2_arch_opposing_treat === 'current';
  };

  smartWireWarning = (warning_msg) => {
    if (warning_msg) {
      return (
        <div className="col-md-12">
          <AlertContainer>
            {warning_msg.split('.').map((sentence, ind) => {
              return (
                <span key={ind}>
                  {sentence}
                  <br />
                </span>
              );
            })}
          </AlertContainer>
        </div>
      );
    }

    return null;
  };

  smartWireWornLessThan6Weeks = (date) => {
    let less_than_6_weeks = false;

    if (date) {
      const days = Moment().diff(Moment(date), 'days');
      const six_weeks_in_days = 6 * 7;

      less_than_6_weeks = days < six_weeks_in_days;
    }

    return less_than_6_weeks;
  };

  showSmartWireDateWarning = () => {
    // Standard warning for stage 1 wire
    let warning_msg = '';
    const upper_smartwire = this.props.de2_upper_smartwire;
    const upper_smartwire_date = this.props.de2_upper_smartwire_date;
    const lower_smartwire = this.props.de2_lower_smartwire;
    const lower_smartwire_date = this.props.de2_lower_smartwire_date;

    if (upper_smartwire === 'stage1' || lower_smartwire === 'stage1') {
      warning_msg = `Please insert the ${nomenclature.SMARTWIRE_2} and allow it to fully express before submitting for Digital Enhancement`;
    }

    if (
      (upper_smartwire !== 'none' && upper_smartwire !== 'stage1' && upper_smartwire_date !== '' && this.smartWireWornLessThan6Weeks(upper_smartwire_date)) ||
      (lower_smartwire !== 'none' &&
        lower_smartwire !== 'stage1' &&
        lower_smartwire !== 'stage2a' &&
        lower_smartwire !== 'de_16_straight' &&
        lower_smartwire_date !== '' &&
        this.smartWireWornLessThan6Weeks(lower_smartwire_date))
    ) {
      if (warning_msg !== '') {
        warning_msg = warning_msg + '.';
      }
      warning_msg = warning_msg + 'Please ensure there has been no observed tooth movement for the last 4-8 weeks';
    }

    if (!this.props.reupload) {
      if (warning_msg) {
        this.props.getDeHold(true);
      } else {
        this.props.getDeHold(false);
      }
    }

    return this.smartWireWarning(warning_msg);
  };

  getFormClass() {
    return this.props.record_states.length > 0 ? 'col-md-8' : 'col-md-12';
  }

  render() {
    return (
      <div className="row">
        <form className={this.getFormClass()}>
          <div className="row">
            <div className="col-md-12">
              <TextAreaForm
                heading={'Reason(s) for Digital Enhancement'}
                field={'de2_reason'}
                description="Please describe the reason(s) for Digital Enhancement"
                value={this.props.de2_reason}
                onChange={this.props.onInputChange_de2}
                onFocus={this.props.onFocus}
                dataTestId="de2_reason_box"
              />
            </div>

            <div className="col-md-12">
              <h3 className="tx-heading">Treatment Details</h3>
              <div className="row">
                <div className="col-md-6">
                  <DateArea
                    heading={'Date of Fitting Appointment*'}
                    field={'de2_initial_date'}
                    onChange={this.props.onInputChange_de2}
                    value={this.props.de2_initial_date}
                    onFocus={this.props.onFocus}
                    warning={this.props.de2_initial_date_warning}
                    dataTestId="de2_initial_date_box"
                  />
                </div>
                <div className="col-md-6">
                  <DateArea
                    heading={'Date of Digital Enhancement Toothprints*'}
                    field={'de2_intra_date'}
                    onChange={this.props.onInputChange_de2}
                    value={this.props.de2_intra_date}
                    onFocus={this.props.onFocus}
                    warning={this.props.de2_intra_date_warning}
                    dataTestId="de2_intra_date_box"
                  />
                </div>

                <div className="col-md-12">
                  <div className="question-box" data-testid="de2_smartwire_box">
                    <span className="title inline emphasis">
                      Which Smartwire<span className="trademark">&reg;</span> was most recently inserted?
                    </span>

                    <CardContainer type="form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="emphasis">Upper Smartwire:</div>
                          <RadioAreaWithDate radios={this.props.gen_2 ? this.upper_smartwire_gen2 : this.upper_smartwire} dataTestIdPrefix={'de3_smartwire'} />
                        </div>

                        <div className="col-md-6">
                          <div className="emphasis">Lower Smartwire:</div>
                          <RadioAreaWithDate radios={this.props.gen_2 ? this.lower_smartwire_gen2 : this.lower_smartwire} dataTestIdPrefix={'de3_smartwire'} />
                        </div>

                        {!this.props.gen_2 && this.showSmartWireDateWarning()}
                      </div>
                    </CardContainer>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <h3 className="tx-heading">Digital Enhancement Smile Design</h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="question-box" data-testid="de2_arch_to_treat_box">
                    <span id="arch_to_treat" className="title emphasis">
                      Arch to treat for Digital Enhancement{' '}
                    </span>

                    <CardContainer type="form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_arch_to_treat-both"
                                className="form-check-input"
                                type="radio"
                                name="de2_arch_to_treat"
                                data-field="de2_arch_to_treat"
                                value="both"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_arch_to_treat === 'both'}
                              />{' '}
                              Both arches{' '}
                            </label>{' '}
                          </div>

                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_arch_to_treat-upper"
                                className="form-check-input"
                                type="radio"
                                name="de2_arch_to_treat"
                                data-field="de2_arch_to_treat"
                                value="upper"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_arch_to_treat === 'upper'}
                              />{' '}
                              Upper arch (Smartwire provided for upper arch only){' '}
                            </label>{' '}
                          </div>

                          {this.props.de2_arch_to_treat === 'upper' ? (
                            <React.Fragment>
                              <div className="ib-checkbox ib-arch ib-arch-sidebyside">
                                <label className="form-check-label ">
                                  <input
                                    id="de2_arch_opposing_treat-lower"
                                    type="radio"
                                    name="de2_arch_opposing_treat"
                                    className="form-check-input"
                                    data-field="de2_arch_opposing_treat"
                                    value="current"
                                    checked={this.props.de2_arch_opposing_treat === 'current'}
                                    onChange={this.props.onInputChange_de2}
                                  />{' '}
                                  Use current lower arch for Smile Design
                                </label>
                              </div>
                              <div className="ib-checkbox ib-arch ib-arch-sidebyside">
                                <label className="form-check-label ">
                                  <input
                                    id="de2_arch_opposing_treat-both"
                                    type="radio"
                                    name="de2_arch_opposing_treat"
                                    className="form-check-input"
                                    data-field="de2_arch_opposing_treat"
                                    value="both"
                                    checked={this.props.de2_arch_opposing_treat === 'both'}
                                    onChange={this.props.onInputChange_de2}
                                  />{' '}
                                  Set up both arches
                                </label>
                              </div>
                            </React.Fragment>
                          ) : null}

                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_arch_to_treat-lower"
                                className="form-check-input"
                                type="radio"
                                name="de2_arch_to_treat"
                                data-field="de2_arch_to_treat"
                                value="lower"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_arch_to_treat === 'lower'}
                              />{' '}
                              Lower arch (Smartwire provided for lower arch only){' '}
                            </label>{' '}
                          </div>

                          {this.props.de2_arch_to_treat === 'lower' ? (
                            <React.Fragment>
                              <div className="ib-checkbox ib-arch ib-arch-sidebyside">
                                <label className="form-check-label ">
                                  <input
                                    id="de2_arch_opposing_treat-upper"
                                    type="radio"
                                    name="de2_arch_opposing_treat"
                                    className="form-check-input"
                                    data-field="de2_arch_opposing_treat"
                                    value="current"
                                    checked={this.props.de2_arch_opposing_treat === 'current'}
                                    onChange={this.props.onInputChange_de2}
                                  />{' '}
                                  Use current upper arch for Smile Design
                                </label>
                              </div>
                              <div className="ib-checkbox ib-arch ib-arch-sidebyside">
                                <label className="form-check-label ">
                                  <input
                                    id="de2_arch_opposing_treat-both"
                                    type="radio"
                                    name="de2_arch_opposing_treat"
                                    className="form-check-input"
                                    data-field="de2_arch_opposing_treat"
                                    value="both"
                                    checked={this.props.de2_arch_opposing_treat === 'both'}
                                    onChange={this.props.onInputChange_de2}
                                  />{' '}
                                  Set up both arches
                                </label>
                              </div>
                            </React.Fragment>
                          ) : null}
                        </div>
                        {this.props.de2_prev_case_info &&
                        this.props.de2_prev_case_info.exist &&
                        this.props.de2_prev_case_info.arch_to_treat !== this.props.de2_arch_to_treat ? (
                          <div className="col-md-12">
                            <AlertContainer>This arch selection differs from the previous submission.</AlertContainer>
                          </div>
                        ) : null}
                      </div>
                    </CardContainer>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="question-box" data-testid="de2_setup_box">
                    <span id="de2_setup" className="title emphasis">
                      How would you like your Digital Enhancement set up?
                    </span>

                    <CardContainer type="form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_setup-same"
                                className="form-check-input"
                                type="radio"
                                name="de2_setup"
                                data-field="de2_setup"
                                value="same"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_setup === 'same'}
                              />{' '}
                              Same final objective as the most recently approved Smile Design{' '}
                            </label>{' '}
                          </div>

                          {this.props.de2_setup === 'same' ? (
                            <div>
                              <div className="accompany-text">Please describe unmet treatment objectives</div>
                              <TextArea
                                rows="3"
                                type="text"
                                className="form-control inline-block fluid-size"
                                placeholder=""
                                data-field="de2_setup_instruction"
                                onChange={this.props.onInputChange_de2}
                                value={this.props.de2_setup_instruction}
                                onFocus={this.props.onFocus}
                              />
                            </div>
                          ) : null}

                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_setup-detail"
                                className="form-check-input"
                                type="radio"
                                name="de2_setup"
                                data-field="de2_setup"
                                value="detail"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_setup === 'detail'}
                              />{' '}
                              Accept current occlusion but improve on detailing{' '}
                            </label>{' '}
                          </div>

                          {this.props.de2_setup === 'detail' ? (
                            <div>
                              <div className="accompany-text">Please specify detailing</div>
                              <TextArea
                                rows="3"
                                type="text"
                                className="form-control inline-block fluid-size"
                                placeholder=""
                                data-field="de2_setup_instruction"
                                onChange={this.props.onInputChange_de2}
                                value={this.props.de2_setup_instruction}
                                onFocus={this.props.onFocus}
                              />
                            </div>
                          ) : null}

                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_setup-other"
                                className="form-check-input"
                                type="radio"
                                name="de2_setup"
                                data-field="de2_setup"
                                value="other"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_setup === 'other'}
                              />{' '}
                              Other changes{' '}
                            </label>{' '}
                          </div>

                          {this.props.de2_setup === 'other' ? (
                            <div>
                              <div className="accompany-text">Please instruct specific tooth movements</div>
                              <TextArea
                                rows="3"
                                type="text"
                                className="form-control inline-block fluid-size"
                                placeholder=""
                                data-field="de2_setup_instruction"
                                onChange={this.props.onInputChange_de2}
                                value={this.props.de2_setup_instruction}
                                onFocus={this.props.onFocus}
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </CardContainer>
                  </div>
                </div>

                <div className="col-md-12">
                  <TeethArea
                    heading="Select any bracket(s) you would like to add for treatment"
                    field="de2_bracket_add"
                    teeth={this.props.de2_bracket_add}
                    onClick={this.props.onInputChange_de2}
                    warning={
                      _.intersection(['UR7', 'UL7'], this.props.de2_bracket_add).length >= 1 && this.hasUpper() ? (
                        <AlertContainer>Brackets on upper 7's will be added if there is enough room on the lingual surface.</AlertContainer>
                      ) : null
                    }
                    hasUpper={this.hasUpper()}
                    hasLower={this.hasLower()}
                    dataTestId="de2_bracket_add_box"
                  />
                </div>

                <div className="col-md-12">
                  <TeethArea
                    heading="Select any bracket(s) you would like to remove from treatment"
                    field="de2_bracket_remove"
                    teeth={this.props.de2_bracket_remove}
                    onClick={this.props.onInputChange_de2}
                    disabledTeeth={this.props.de2_bracket_add}
                    hasUpper={this.hasUpper()}
                    hasLower={this.hasLower()}
                    dataTestId="de2_bracket_remove_box"
                  />
                </div>

                <div className="col-md-12">
                  <TeethArea
                    heading="Do not move the following teeth"
                    sub_heading={this.getRestrictionMsg()}
                    field="de2_teeth_restriction"
                    teeth={this.props.de2_teeth_restriction}
                    onClick={this.props.onInputChange_de2}
                    hasUpper={this.hasUpper() || this.hasByPassCondition()}
                    hasLower={this.hasLower() || this.hasByPassCondition()}
                    dataTestId="de2_teeth_restriction_box"
                  />
                </div>

                <div className="col-md-12">
                  <div className="question-box" data-testid="de2_ipr_box">
                    <span id="ap_relationship_title" className="title emphasis">
                      IPR for Digital Enhancement
                    </span>
                    <CardContainer type="form">
                      <div>
                        <div className="ib-radio">
                          <label className="form-check-label ">
                            <input
                              id="de2_ipr-allow"
                              className="form-check-input"
                              type="radio"
                              name="de2_ipr"
                              data-field="de2_ipr"
                              value="allow"
                              onChange={this.props.onInputChange_de2}
                              defaultChecked={this.props.de2_ipr === 'allow'}
                            />{' '}
                            Allow InBrace to include IPR as needed
                          </label>{' '}
                        </div>
                        <div className="ib-radio">
                          <label className="form-check-label ">
                            <input
                              id="de2_ipr-not"
                              className="form-check-input"
                              type="radio"
                              name="de2_ipr"
                              data-field="de2_ipr"
                              value="not"
                              onChange={this.props.onInputChange_de2}
                              defaultChecked={this.props.de2_ipr === 'not'}
                            />{' '}
                            Do not include any IPR
                          </label>{' '}
                        </div>
                        <div className="ib-radio">
                          <label className="form-check-label ">
                            <input
                              id="de2_ipr-special"
                              className="form-check-input"
                              type="radio"
                              name="de2_ipr"
                              data-field="de2_ipr"
                              value="special"
                              onChange={this.props.onInputChange_de2}
                              defaultChecked={this.props.de2_ipr === 'special'}
                            />{' '}
                            Additional IPR requirements
                          </label>{' '}
                        </div>

                        {this.props.de2_ipr === 'special' ? (
                          <div className="form-group aux-box">
                            <TextArea
                              className="form-control"
                              rows="2"
                              placeholder="Specify IPR requirements"
                              data-field="de2_ipr_instruction"
                              value={this.props.de2_ipr_instruction}
                              onChange={this.props.onInputChange_de2}
                              onFocus={this.props.onFocus}
                            />
                          </div>
                        ) : null}
                      </div>
                    </CardContainer>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="question-box" data-testid="de2_spacing_box">
                    <span id="spacing_title" className="title emphasis">
                      Specify the amount of space (if any) to be kept{' '}
                    </span>
                    <CardContainer type="form">
                      <div id="spacing-box">
                        <div className="ib-radio">
                          <label className="form-check-label ">
                            <input
                              id="de2_spacing-close"
                              className="form-check-input"
                              type="radio"
                              name="de2_spacing"
                              data-field="de2_spacing"
                              value="close"
                              onChange={this.props.onInputChange_de2}
                              defaultChecked={this.props.de2_spacing === 'close'}
                            />{' '}
                            Close all spaces{' '}
                          </label>{' '}
                        </div>
                        <div className="ib-radio">
                          <label className="form-check-label ">
                            <input
                              id="de2_spacing-leave"
                              className="form-check-input"
                              type="radio"
                              name="de2_spacing"
                              data-field="de2_spacing"
                              value="leave"
                              onChange={this.props.onInputChange_de2}
                              defaultChecked={this.props.de2_spacing === 'leave'}
                            />{' '}
                            Leave spaces (mm){' '}
                          </label>{' '}
                        </div>
                        {this.props.de2_spacing === 'leave' ? (
                          <div className="spacing-chart-zone">
                            {this.hasUpper() || this.hasByPassCondition() ? (
                              <div className={`spacing-center upper ${this.oneArchIsBeingDisplayed() ? 'mb' : ''}`}>
                                <table className="spacing-chart-input-U">
                                  <tbody>
                                    <tr className="tx-instruction">
                                      <td />
                                      {this.getSpacingArray.map((x) => {
                                        return (
                                          <td key={x}>
                                            <div>
                                              {this.getSpacing(x, this.props.de2_spacing_upper) ? (
                                                <span className="spacing-chart-x-upper" onClick={() => this.props.clearSpacingValue(x, 'de2_spacing_upper')}>
                                                  x
                                                </span>
                                              ) : null}
                                              <input
                                                className={
                                                  this.getSpacing(x, this.props.de2_spacing_upper)
                                                    ? 'spacing-chart-input-upper spacing-chart-input-upper-lrg'
                                                    : 'spacing-chart-input-upper'
                                                }
                                                placeholder="0.0"
                                                value={this.getSpacing(x, this.props.de2_spacing_upper)}
                                                data-field="de2_spacing_upper"
                                                data-index={x}
                                                onChange={this.props.onInputChange_de2}
                                                onBlur={() => {
                                                  this.props.onChangeSpacingOnBlur(x, 'de2_spacing_upper');
                                                }}
                                                onFocus={this.props.onFocus}
                                              />
                                            </div>
                                          </td>
                                        );
                                      })}
                                      <td />
                                      <td />
                                    </tr>
                                  </tbody>
                                </table>
                                <table className="spacing-chart">
                                  <tbody>
                                    <tr>
                                      <td className="spacing-chart-upper-label spacing-chart-upper-label-hidden" />
                                      <td className="spacing-chart-upper-label">UR7</td>
                                      <td className="spacing-chart-upper-label">UR6</td>
                                      <td className="spacing-chart-upper-label">UR5</td>
                                      <td className="spacing-chart-upper-label">UR4</td>
                                      <td className="spacing-chart-upper-label">UR3</td>
                                      <td className="spacing-chart-upper-label">UR2</td>
                                      <td className="spacing-chart-upper-label">UR1</td>
                                      <td className="spacing-chart-upper-label">UL1</td>
                                      <td className="spacing-chart-upper-label">UL2</td>
                                      <td className="spacing-chart-upper-label">UL3</td>
                                      <td className="spacing-chart-upper-label">UL4</td>
                                      <td className="spacing-chart-upper-label">UL5</td>
                                      <td className="spacing-chart-upper-label">UL6</td>
                                      <td className="spacing-chart-upper-label spacing-chart-upper-label-hidden">UL7</td>
                                      <td className="spacing-chart-upper-label spacing-chart-upper-label-hidden" />
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : null}

                            {this.hasLower() || this.hasByPassCondition() ? (
                              <div className={`spacing-center lower ${this.oneArchIsBeingDisplayed() ? 'mt' : ''}`}>
                                <table className="spacing-chart">
                                  <tbody>
                                    <tr>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label spacing-chart-lower-label-hidden'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-hidden spacing-chart-lower-label-border'
                                        }
                                      />
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR7
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR6
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR5
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR4
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR3
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR2
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LR1
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL1
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL2
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL3
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL4
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL5
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL6
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label spacing-chart-lower-label-hidden'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-hidden spacing-chart-lower-label-border'
                                        }
                                      >
                                        LL7
                                      </td>
                                      <td
                                        className={
                                          this.hasUpper() || this.hasByPassCondition()
                                            ? 'spacing-chart-lower-label spacing-chart-lower-label-hidden'
                                            : 'spacing-chart-lower-label spacing-chart-lower-label-hidden spacing-chart-lower-label-border'
                                        }
                                      />
                                    </tr>
                                  </tbody>
                                </table>
                                <table className="spacing-chart-input-L">
                                  <tbody>
                                    <tr className="tx-instruction">
                                      <td> </td>
                                      {this.getSpacingArray.map((x) => {
                                        return (
                                          <td key={x}>
                                            <div>
                                              <input
                                                className={
                                                  this.getSpacing(x, this.props.de2_spacing_lower)
                                                    ? 'spacing-chart-input-lower spacing-chart-input-lower-lrg'
                                                    : 'spacing-chart-input-lower'
                                                }
                                                placeholder="0.0"
                                                value={this.getSpacing(x, this.props.de2_spacing_lower)}
                                                data-field="de2_spacing_lower"
                                                data-index={x}
                                                onChange={this.props.onInputChange_de2}
                                                onBlur={() => {
                                                  this.props.onChangeSpacingOnBlur(x, 'de2_spacing_lower');
                                                }}
                                                onFocus={this.props.onFocus}
                                              />
                                              {this.getSpacing(x, this.props.de2_spacing_lower) ? (
                                                <span className="spacing-chart-x-lower" onClick={() => this.props.clearSpacingValue(x, 'de2_spacing_lower')}>
                                                  x
                                                </span>
                                              ) : null}
                                            </div>
                                          </td>
                                        );
                                      })}
                                      <td> </td>
                                      <td> </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ) : null}

                            <div className="question-box" data-testid="de-spacing-instructions">
                              <span className="title emphasis">Additional spacing instructions: </span>
                              <div className="form-group">
                                <TextArea
                                  id="notes_spacing"
                                  rows="4"
                                  placeholder="Please describe any additional spacing instructions"
                                  data-field="de2_spacing_instruction"
                                  value={this.props.de2_spacing_instruction}
                                  onChange={this.props.onInputChange_de2}
                                  onFocus={this.props.onFocus}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </CardContainer>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="question-box" data-testid="de2_setup_additional_instruction_box">
                    <span id="de2_setup_additional_instruction" className="title emphasis">
                      Allow InBrace to perform additional Smile Design adjustments as needed?
                    </span>

                    <CardContainer type="form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_setup_additional_instruction-allow"
                                className="form-check-input"
                                type="radio"
                                name="de2_setup_additional_instruction"
                                data-field="de2_setup_additional_instruction"
                                value="yes"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_setup_additional_instruction === 'yes'}
                              />{' '}
                              Yes (standard approach)
                            </label>{' '}
                          </div>
                          <div className="ib-radio">
                            <label className="form-check-label ">
                              <input
                                id="de2_setup_additional_instruction-only"
                                className="form-check-input"
                                type="radio"
                                name="de2_setup_additional_instruction"
                                data-field="de2_setup_additional_instruction"
                                value="no"
                                onChange={this.props.onInputChange_de2}
                                defaultChecked={this.props.de2_setup_additional_instruction === 'no'}
                              />{' '}
                              No thanks. Perform only what I have specified
                            </label>{' '}
                          </div>
                        </div>
                      </div>
                    </CardContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-padding-t1">
            <div className="col-md-12">
              <TextAreaForm
                heading={'Special Instructions'}
                field={'de2_special_instruction'}
                description="Describe any special instructions or conditions"
                value={this.props.de2_special_instruction}
                onChange={this.props.onInputChange_de2}
                onFocus={this.props.onFocus}
                dataTestId="de2_special_instruction_box"
              />
            </div>
          </div>
        </form>
        {this.props.record_states.length > 0 && (
          <div className="col-md-4 tr-viewer-container-parent">
            <div className="tr-viewer-container">
              <RecordViewer
                records={this.props.record_states}
                onLoad={this.props.onLoadImageRecord}
                onClick={(id, action) => {
                  onImageToolAction(id, action, this.props);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    record_states: getRecordStates(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DigitalEnhancementForm2);
