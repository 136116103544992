import Axios from 'axios';
export const SET_DXF_FILE_NAME_ERROR = 'SET_DXF_FILE_NAME_ERROR';
export const SET_WIRE_MANUFACTURING_STATUS = 'SET_WIRE_MANUFACTURING_STATUS';
export const SET_WIRE_MANUFACTURING_STATUS_LOG = 'SET_WIRE_MANUFACTURING_STATUS_LOG';
export const SET_CUSTOM_WO_FILE_NAME_ERROR = 'SET_CUSTOM_WO_FILE_NAME_ERROR';
export const SET_WIRE_SELECTION_INFO = 'SET_WIRE_SELECTION_INFO';
export const SET_WIRE_SELECTION_STATUS = 'SET_WIRE_SELECTION_STATUS';
export const SET_WS_ASSIGNED_TO = 'SET_WS_ASSIGNED_TO';
export const SET_WS_GUIDE_FILE_NAME_ERROR = 'SET_WS_GUIDE_FILE_NAME_ERROR';
export const SET_WS_PROGRAMS = 'SET_WS_PROGRAMS';

/**
 * Sets error for incorrect DXF upload file name
 * @function
 * @param {boolean} error - True or false
 * @param {string} error_type - Type of file name error (case_id or format)
 * @param {string} wire_name - Wire name for dxf file
 * @returns {object} - Action type
 */
export function setDxfFileNameError(error, error_type = '', wire_name = '') {
  return {
    type: SET_DXF_FILE_NAME_ERROR,
    error: error,
    error_type: error_type,
    wire_name: wire_name,
  };
}

export function setCustomWoFileNameError(error, error_type = '') {
  return {
    type: SET_CUSTOM_WO_FILE_NAME_ERROR,
    error: error,
    error_type: error_type,
  };
}

/**
 * Retrieves wire manufacturing status
 * @function
 * @param {string} case_id - Case id
 * @returns {object} - Action type
 */
export function fetchWireManufacturingStatus(case_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/wiremanufacturing/${case_id}`)
      .then((res) => {
        if (res && res.data) {
          dispatch(setWireManufacturingStatus(res.data.wm_status));
          dispatch(setWireManufacturingStatusLog(res.data.wm_status_log));
        }
      })
      .catch((error) => {});
  };
}

/**
 * Sets wire manufacturing status
 * @function
 * @param {string} wm_status - Wire manufacturing status
 * @returns {object} - Action type
 */
export function setWireManufacturingStatus(wm_status) {
  return {
    type: SET_WIRE_MANUFACTURING_STATUS,
    wm_status: wm_status,
  };
}

/**
 * Sets wire manufacturing status history log
 * @function
 * @param {list} wm_status_log - Status log
 * @returns {object} - Action type
 */
export function setWireManufacturingStatusLog(wm_status_log) {
  return {
    type: SET_WIRE_MANUFACTURING_STATUS_LOG,
    wm_status_log: wm_status_log,
  };
}

/**
 * Retrieves wire selection status
 * @function
 * @param {string} case_id - Case id
 * @returns {object} - Action type
 */
export function fetchWireSelectionStatus(case_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/wireselection/${case_id}`).then(function (res) {
      if (res.data) {
        dispatch(setWireSelectionInfo(res.data));
      }
    });
  };
}

/**
 * Sets wire selection info
 * @function
 * @param {object} ws_data - Wire selection info
 * @returns {object} - Action type
 */
export function setWireSelectionInfo(ws_data) {
  return { ...ws_data, type: SET_WIRE_SELECTION_INFO };
}

/**
 * Sets wire selection status
 * @function
 * @param {string} ws_status - Wire selection status
 * @returns {object} - Action type
 */
export function setWireSelectionStatus(ws_status) {
  return {
    type: SET_WIRE_SELECTION_STATUS,
    ws_status: ws_status,
  };
}

/**
 * Sets wire selection assigned to
 * @function
 * @param {object} ws_assigned_to - Assigned to info
 * @returns {object} - Action type
 */
export function setWsAssignedTo(ws_assigned_to) {
  return {
    type: SET_WS_ASSIGNED_TO,
    ws_assigned_to: ws_assigned_to,
  };
}

/**
 * Sets ws guide file name error
 * @function
 * @param {boolean} error - True or false
 * @returns {object} - Action type
 */
export function setWsGuideFileNameError(error) {
  return {
    type: SET_WS_GUIDE_FILE_NAME_ERROR,
    error: error,
  };
}

export function setWsPrograms(ws_programs) {
  return {
    type: SET_WS_PROGRAMS,
    ws_programs: ws_programs,
  };
}

/**
 * Fetches Doctors Enrolled Programs and stores them in redux
 * @param {int} doctor_id
 * @returns
 */
export function fetchWireSelectionPrograms(doctor_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/programenrollment/${doctor_id}`)
      .then(function (res) {
        const programs = [];
        for (let program of res.data.doctor_programs) {
          if (program.program_type === 'custom_wire' || program.program_id === 'universal_template_wires') {
            programs.push(program);
          }
        }
        dispatch(setWsPrograms(programs));
      })
      .catch((e) => console.log(e));
  };
}
