import {
  SET_WIRE_FABRICATION_REVIEW_STATUS,
  SET_WIRE_FABRICATION_REVIEW_STATUS_LOG,
  SET_SELECTED_PRODUCTION_STAGE,
  SET_INITIATE_WIRE_FABRICATION_REVIEW_MODAL,
  SET_WIRE_FABRICATION_REVIEW_CONFIRM_MODAL,
  SET_WS_WIRES,
  SET_WIRE_FABRICATION_REVIEW_ERROR,
  SET_WIRES_PREV_REV,
  SET_WIRE_FABRICATION_REVIEW_RESOLVE_MODAL,
  SET_PRODUCTION_LOG_MODAL,
  SET_REFRESH,
} from '../../../actions/bpp/wire_selection/wire_fabrication_review';

const initialState = {
  wire_fabrication_review_status: '',
  wire_fabrication_review_status_log: [],
  selected_production_stage: 'labels',
  initiate_wire_fabrication_review_modal: false,
  wire_fabrication_review_confirm_modal: false,
  wires: [],
  wires_prev_rev: [],
  wire_fabrication_review_error: false,
  wire_fabrication_review_resolve_modal: false,
  production_log_modal: false,
  refresh: false,
};

export function wireFabricationReviewReducer(state = initialState, action) {
  switch (action.type) {
    case SET_WIRE_FABRICATION_REVIEW_STATUS:
      return {
        ...state,
        wire_fabrication_review_status: action.status,
      };

    case SET_WIRE_FABRICATION_REVIEW_STATUS_LOG:
      return {
        ...state,
        wire_fabrication_review_status_log: action.status_log,
      };

    case SET_SELECTED_PRODUCTION_STAGE:
      return {
        ...state,
        selected_production_stage: action.stage,
      };

    case SET_INITIATE_WIRE_FABRICATION_REVIEW_MODAL:
      return {
        ...state,
        initiate_wire_fabrication_review_modal: action.open,
      };

    case SET_WIRE_FABRICATION_REVIEW_CONFIRM_MODAL:
      return {
        ...state,
        wire_fabrication_review_confirm_modal: action.open,
      };

    case SET_WS_WIRES:
      return {
        ...state,
        wires: action.wires,
      };

    case SET_WIRES_PREV_REV:
      return {
        ...state,
        wires_prev_rev: action.wires_prev_rev,
      };

    case SET_WIRE_FABRICATION_REVIEW_ERROR:
      return {
        ...state,
        wire_fabrication_review_error: action.error,
      };

    case SET_WIRE_FABRICATION_REVIEW_RESOLVE_MODAL:
      return {
        ...state,
        wire_fabrication_review_resolve_modal: action.open,
      };

    case SET_PRODUCTION_LOG_MODAL:
      return {
        ...state,
        production_log_modal: action.production_log_modal,
      };

    case SET_REFRESH:
      return {
        ...state,
        refresh: action.status,
      };

    default:
      return state;
  }
}

export const getWireFabricationReviewStatus = (state) => state.wireFabricationReviewReducer.wire_fabrication_review_status;
export const getWireFabricationReviewStatusLog = (state) => state.wireFabricationReviewReducer.wire_fabrication_review_status_log;
export const getSelectedProductionStage = (state) => state.wireFabricationReviewReducer.selected_production_stage;
export const getInitiateWireFabricationReviewModal = (state) => state.wireFabricationReviewReducer.initiate_wire_fabrication_review_modal;
export const getWireFabricationReviewConfirmModal = (state) => state.wireFabricationReviewReducer.wire_fabrication_review_confirm_modal;
export const getWires = (state) => state.wireFabricationReviewReducer.wires;
export const getWiresPrevRev = (state) => state.wireFabricationReviewReducer.wires_prev_rev;
export const getWireFabricationReviewError = (state) => state.wireFabricationReviewReducer.wire_fabrication_review_error;
export const getWireFabricationReviewResolveModal = (state) => state.wireFabricationReviewReducer.wire_fabrication_review_resolve_modal;
export const getProductionLogModal = (state) => state.wireFabricationReviewReducer.production_log_modal;
export const getRefreshStatus = (state) => state.wireFabricationReviewReducer.refresh;
