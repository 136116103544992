import _ from 'lodash';
import React, { Component } from 'react';
import './file_upload.scss';
import Axios from 'axios';
import Dropzone from 'react-dropzone';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { v4 as uuidv4 } from 'uuid';
import { setTokenHeader, configLightBox, removeCaseIdInitialNumber, onImageToolAction } from '../../common/functions';
import { getCaseIdFromSubmissionRoute } from '../../common/route';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Lightbox from '../custom_lightbox/Lightbox';
import {
  isThreeMaticFile,
  hasData,
  validateCustomWo,
  hasValidDxfFileName,
  hasValidStageIFileName,
  isValidFileName,
  validateWsGuide,
  validateSIDBfilename,
  waitMs,
} from './file_upload_helpers';
import { isDigiBpFile } from '../../common/helpers';
import { handleHttpRequestError } from '../../common/error';
import SessionExpire from '../../common/session_expire';
import { FilesServiceInstance } from '../../common/services/files.service';
import { getIteroScansRecords, getIteroProgressRecords, removeDuplicateIteroScansRecords } from '../../common/itero_utils';

import RecordViewer from '../../doctor/components/record_viewer/record_viewer';
import AlertContainer from '../../doctor/components/container/alert_container';
import UploadMethodSelector from '../../doctor/itero_files/upload_method_selector';
import IteroSelection from '../../doctor/itero_files/itero_selection';
import { getRecordStates } from '../../redux/reducers/record_viewer/record_viewer';
import { buildRecordStates, onLoadImageRecord, onRecordViewerAction, updateDeleteInProcess } from '../../redux/actions/record_viewer/record_viewer';

//Redux Actions
import {
  setIfuFileName,
  setIFUIncorrectFileNameError,
  setUploadPending,
  postIfuCaseFileSuccess,
  setIfuFileTypeError,
  clearIfuFileTypeError,
  setIfuFolder,
} from '../../redux/actions/ifu/ifu_upload';
import {
  uploadDdmInputFilePending,
  setDdmFileName,
  uploadDdmInputFileSuccess,
  setDDMIncorrectFileNameError,
  setDdmFileTypeError,
  clearDdmFileTypeError,
} from '../../redux/actions/ddm/ddm_upload';
import { LogOutIcon } from '../../doctor/assets/icons';
import CircleLoader from '../loader/circle_loader';

// Redux State
import { getPending, getDdmFileName } from '../../redux/reducers/ddm/ddm_upload';
import { getUploadPending, getIfuFileName, getIfuFolder } from '../../redux/reducers/ifu/ifu_upload';
import { getIfuState } from '../../redux/reducers/ifu/ifu_state';
import { getDdmState } from '../../redux/reducers/ddm/ddm_state';
import { setDxfFileNameError, setCustomWoFileNameError, setWsGuideFileNameError } from '../../redux/actions/bpp/wire_selection/wire_selection';
import { getWsWoHistory } from '../../redux/reducers/bpp/wire_selection/wire_selection';
import { WindowUtils } from '../../common/utils/window-utils';
import ImageTransformer from '../../common/utils/image-transformer';

let uuidv3 = require('uuid/v3');
let DropboxInstance = require('dropbox').Dropbox;

const BootstrapTooltip = withStyles({
  arrow: {
    color: 'rgba(0,0,0,0.8)',
  },
  tooltip: {
    fontSize: '12px',
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
})(Tooltip);

const folderToClassNamesMap = {
  upperscans: 'col-lg-12 center-text',
  lowerscans: 'col-lg-12 center-text',
  single_tooth_idb: 'col-lg-12 center-text',
  setup: 'col-lg-12 center-text',
  ip_upload: 'col-lg-12 center-text',
  ifu_upload: 'col-lg-12 center-text centered',
  ddm_upload: 'col-lg-12 center-text',
  digi_bp: 'col-lg-12 center-text',
  final_design: 'col-lg-12 center-text',
  adjusted_setup: 'relative',
  navigator: 'navigator_upload',
};

class FileUpload extends Component {
  static defaultProps = {
    showDropzone: true,
    showIteroSelection: false,
    selectedIteroScanRecords: [],
    isLoadingIteroPhotos: false,
    isDeletingIteroPhotos: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      accepted: true,
      uploading: false,
      files: [],
      removed_id: '',
      idb_error: '',

      images: [],
      lightboxIsOpen: false,
      lightboxCurrent: 0,
      refresh: false,
      isDropRejected: false,
    };

    this.onDrop = this.onDrop.bind(this);
    this.isSTLandhasFile = this.isSTLandhasFile.bind(this);
    this.onThumbnailLoad = this.onThumbnailLoad.bind(this);
    this.hasSameFileName = this.hasSameFileName.bind(this);
    this.hasUploadContent = this.hasUploadContent.bind(this);
  }

  componentDidMount() {
    setTokenHeader();
    window.$('[data-toggle="tooltip"]').tooltip();
    this.buildImageRecords();

    if (this.hasUploadContent()) {
      this.setState({
        accepted: false,
        uploading: true,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    window.$('[data-toggle="tooltip"]').tooltip();

    if (!this.state.accepted && this.state.uploading && !this.hasUploadContent()) {
      this.setState({
        uploading: false,
      });
    }

    const is_business = window.location.href.includes('business');

    if (
      !is_business &&
      (this.props.filesUploaded !== prevProps.filesUploaded ||
        this.props.folder !== prevProps.folder ||
        this.props.selectedIteroScanRecords !== prevProps.selectedIteroScanRecords)
    ) {
      this.buildImageRecords();
    }
  }

  closeLightbox = () => this.setState({ lightboxIsOpen: false });
  gotoNext = () => {
    this.setState((prevState) => ({ lightboxCurrent: Math.min(this.props.filesUploaded.length - 1, prevState.lightboxCurrent + 1) }));
  };
  gotoPrev = () => {
    this.setState((prevState) => ({ lightboxCurrent: Math.max(0, prevState.lightboxCurrent - 1) }));
  };

  onThumbnailClick = (event) => {
    const images = configLightBox(this.props.filesUploaded);
    // eslint-disable-next-line
    let lightboxCurrent = 0;

    if (event.currentTarget.dataset.index) {
      lightboxCurrent = parseInt(event.currentTarget.dataset.index);
    }

    const selector = '.img-preview';
    const allThumbnails = document.querySelectorAll(selector);
    for (let i = 0; i < allThumbnails.length; i++) {
      if (allThumbnails[i].className.includes('ignore-thumbnail')) {
        //ignore
      } else if (allThumbnails[i].src.includes('/static/img') && this.props.filesUploaded[i] && this.props.filesUploaded[i].file_url) {
        images[parseInt(allThumbnails[i].dataset.index)].src = this.props.filesUploaded[i].file_url;
      } else {
        images[parseInt(allThumbnails[i].dataset.index)].src = allThumbnails[i].src;
      }
      const empty_images = ['no-image.png', 'model.png'];
      if (empty_images.some((img) => images[parseInt(allThumbnails[i].dataset.index)].src.includes(img))) {
        images[parseInt(allThumbnails[i].dataset.index)].caption = null;
      }
    }

    this.setState({ images: images });
  };

  buildImageRecords() {
    const { filesUploaded, selectedIteroScanRecords } = this.props;
    let records = [];
    if (filesUploaded) records.push(...filesUploaded, ...selectedIteroScanRecords);
    const hastIteroProgressRecords = getIteroProgressRecords(records).length;
    const hasIteroScans = getIteroScansRecords(records).length;
    if (hastIteroProgressRecords && hasIteroScans) {
      records = removeDuplicateIteroScansRecords(records);
    }
    const options = { teethViewer: false, caseType: 'incomplete', hide_occlusion: this.props.hide_occlusion };
    this.props.buildRecordStates(records, options);
  }

  clearIfuDdmPending = () => {
    this.props.postIfuCaseFileSuccess();
    this.props.uploadDdmInputFileSuccess();
  };

  /**
   * Checks on file drop to see if there is actual upload content
   * @returns {Boolean} Content Status
   */
  hasUploadContent() {
    let has_content = true;

    if ((this.props.upload_content && this.props.upload_content.length === 0) || this.props.upload_content === undefined) {
      has_content = false;
    }

    return has_content;
  }

  /**
   * Handle invalid file type drop
   * @param {*} rejectedFile
   */
  handleDropReject = (rejectedFile) => {
    this.props.setDdmFileTypeError();
    this.props.setIfuFileTypeError();
  };

  hasSameFileName(files) {
    const existing_files = this.props.filesUploaded;

    let same_name = false;

    if (existing_files && existing_files.length > 0) {
      // 2 loop iteration
      for (let i = 0; i < existing_files.length; i++) {
        for (let j = 0; j < files.length; j++) {
          if (existing_files[i].original_filename === files[j].name && !isThreeMaticFile(existing_files[i])) {
            same_name = true;
            break;
          }
        }
      }
    }

    return same_name;
  }

  removeDuplicateFile = (files) => {
    const existing_files = this.props.filesUploaded;
    let same_name = false;
    let newfiles = [];

    // 2 loop iteration
    for (let i = 0; i < files.length; i++) {
      same_name = false;

      for (let j = 0; j < existing_files.length; j++) {
        if (existing_files[j].original_filename === files[i].name && !isThreeMaticFile(existing_files[i])) {
          same_name = true;
          break;
        }
      }

      if (!same_name) {
        newfiles.push(files[i]);
      }
    }

    return newfiles;
  };

  /**
   * Check if ddm file upload dropzone should be large
   * @returns {Boolean} If dropzone should be large
   */
  isLargeDdmDropzone = () => {
    if (this.props.ddmState && (this.props.ddmState.ddm_status === 'DDM Manual Process' || this.props.ddmState.ddm_screen === 'ddm-result')) return true;
    return false;
  };

  /**
   * Checks if existing file is a 3matic zip
   * @param {File} file
   * @returns {Boolean} 3matic File Status
   */
  isThreeMaticFile = (file) => {
    if (['ddm_upload', 'ifu_upload', 'bmf_upload', 'digi_bp', 'ip_upload', 'adjusted_setup', 'setup', 'final_design'].includes(file.file_type)) {
      return true;
    }
    return false;
  };
  /**
   * Set dropbox upload folder for gen 2 cases
   */
  setStageIFolder = (files) => {
    if (this.props.gen_2 && files.length && files[0]['name']) {
      if (isDigiBpFile(files[0]['name'])) {
        this.props.setIfuFolder('digi_bp');
      } else {
        this.props.setIfuFolder('ifu_upload');
      }
    }
  };

  async onDrop(files, rejectedFiles) {
    if (rejectedFiles && rejectedFiles.length > 0) {
      this.setState({ isDropRejected: true });
      return;
    }
    const transformer = new ImageTransformer();
    files = await transformer.transformImagesToJPEG(files);
    setTokenHeader();
    const { folder, location, type, ws_version } = this.props;

    const uploaderEl = document.querySelector(`#uploader${this.props.folder}`);
    const uploaderWarnEl = document.querySelector('#fileUpload-warning');

    const isDdmUploadFolder = this.props.folder === 'ddm_upload';
    const isIfuUploadFolder = this.props.folder === 'ifu_upload';
    const isDigiBpFolder = this.props.folder === 'digi_bp';
    const isScansFolder = this.props.folder.indexOf('scans') >= 0;

    const isDxfType = this.props.type === 'ws_dxf';
    const isWsGuideType = this.props.type === 'ws_guide';
    const isGuideManualLocation = this.props.location === 'ws_guide_manual';
    const isCustomWoLocation = this.props.location === 'ws_custom_wo';

    this.props.clearIfuFileTypeError();
    this.props.clearDdmFileTypeError();
    this.setState({ idb_error: '', isDropRejected: false });

    if (this.props.hide_warning) {
      this.props.hide_warning();
    }

    // if a file is acceptable
    const id = this.props.id ? this.props.id : getCaseIdFromSubmissionRoute();

    if (isIfuUploadFolder || isDdmUploadFolder || isDigiBpFolder) {
      this.props.setIFUIncorrectFileNameError('case_id', false);
      this.props.setIFUIncorrectFileNameError('format', false);
      this.props.setDDMIncorrectFileNameError('case_id', false);
      this.props.setDDMIncorrectFileNameError('format', false);
      if (!hasValidStageIFileName(removeCaseIdInitialNumber(id), files, this.props)) {
        return;
      }
    }

    if (isCustomWoLocation) {
      if (!validateCustomWo(files, this.props)) {
        return false;
      }
    }

    if (this.props.type === 'single_tooth_idb_final') {
      let error = validateSIDBfilename(files, this.props);
      this.setState({ idb_error: error });
      if (error) {
        return false;
      }
    }

    if (isDxfType) {
      if (!hasValidDxfFileName(removeCaseIdInitialNumber(id), files, this.props)) {
        return;
      }
    }

    if (isWsGuideType && ((ws_version && ws_version !== '1.0') || this.props.id.includes('-IR'))) {
      if (!validateWsGuide(removeCaseIdInitialNumber(id), files, this.props)) {
        return;
      }
    }

    if (this.hasSameFileName(files) && folder !== 'repaired_scans') {
      //Display Error here

      if (this.props.show_warning) {
        this.props.show_warning();
      }

      let warning = document.querySelector('#warning-submit');

      if (warning) {
        warning.classList.add('warning-display');
        warning.innerHTML = '<ul class="wizard-error-text"><li>Duplicate File Name Found</li></ul>';
      }

      files = this.removeDuplicateFile(files);
    }

    if (!hasData(files, this.props) && this.props.onEmptyFileError) {
      this.props.onEmptyFileError();
      return false;
    }

    if (files.length && !this.state.uploading && isValidFileName(files, this.props) && !this.hasSameFileName(files) && !this.hasUploadContent()) {
      if (this.props.isUploading) {
        this.props.isUploading(true);
      }
      this.props.clearDdmFileTypeError();
      this.props.clearIfuFileTypeError();

      this.setState({
        accepted: true,
        uploading: true,
        files: files,
      });

      if (files.length === 1 && type === 'ddm_upload') {
        this.props.setDdmFileName(files[0].name);
        if (!this.props.ifuState.upload_file) {
          this.props.setIfuFileName(files[0].name);
        }
      }
      if (files.length === 1 && type === 'ifu_upload') {
        this.props.setIfuFileName(files[0].name);
        if (!this.props.ddmState.upload_file) {
          this.props.setDdmFileName(files[0].name);
        }
      }

      if (this.props.upload_state) {
        this.props.upload_state(files);
      }

      if (uploaderWarnEl) {
        uploaderWarnEl.classList.add('hide');
      }

      const resetUploadingState = () => {
        if (this.props.isUploading) {
          this.props.isUploading(false);
        }

        this.setState({
          accepted: false,
          uploading: false,
          files: [],
        });

        if (this.props.upload_state) {
          this.props.upload_state([]);
        }
      };

      const uploads = [];
      let areAllFilesUploaded = false;

      for (let index = 0; index < files.length; index++) {
        if (index > 0) {
          // wait random time 0-3s before next upload
          // to prevent 429 Dropbox error
          const timeout = Math.ceil(Math.random() * 3000);
          await waitMs(timeout);
        }

        const file = files[index];
        const query = {
          id,
          folder: this.props.folder,
          location: this.props.location,
          index,
        };
        const params = {};

        const isDropboxUsed = FilesServiceInstance.isDropboxUsed(file.size);

        // TODO: WIP solution, should be either removed after testing or add more checks progressively
        const uploadTempLinkIsUsed = FilesServiceInstance.isUploadTempLinkUsed(query);

        if (uploadTempLinkIsUsed) {
          // Set IFU Upload to pending
          if (isDdmUploadFolder) {
            this.props.uploadDdmInputFilePending();
            if (!this.props.ifuState.upload_file) {
              this.props.setIfuUploadPending();
            }
          } else if (isIfuUploadFolder || isDigiBpFolder) {
            this.props.setIfuUploadPending();
            if (!this.props.ddmState.upload_file) {
              this.props.uploadDdmInputFilePending();
            }
          }
          if (isDxfType) {
            params.wire_name = this.props.wire_name;
            params.prev_ws_manual_file_name = this.props.prev_ws_manual_file_name;
            params.ws_id = this.props.ws_id;
            params.wsg_rev = this.props.wsg_rev;
          }
          if (isGuideManualLocation) {
            params.wsg_manual_id = this.props.wsg_manual_id;
            params.replace = this.props.replace;
            params.wsg_rev = this.props.wsg_rev;
          }
          if (isCustomWoLocation) {
            params.wsg_rev = this.props.wsg_rev;
          }
          if (isDxfType || isGuideManualLocation || isCustomWoLocation) {
            params.wsg_rev = this.props.wsg_rev;
            if (isGuideManualLocation) {
              params.replace = this.props.replace;
            }
          }
        } else if (isDropboxUsed) {
          if (isDxfType || isGuideManualLocation || isCustomWoLocation) {
            params.wsg_rev = this.props.wsg_rev;
            if (isGuideManualLocation) {
              params.replace = this.props.replace;
            }
          }

          // Set IFU Upload to pending
          if (isDdmUploadFolder) {
            this.props.uploadDdmInputFilePending();
            if (!this.props.ifuState.upload_file) {
              this.props.setIfuUploadPending();
            }
          } else if (isIfuUploadFolder) {
            this.props.setIfuUploadPending();
            if (!this.props.ddmState.upload_file) {
              this.props.uploadDdmInputFilePending();
            }
          }
        } else {
          if (isDxfType) {
            params.wire_name = this.props.wire_name;
            params.prev_ws_manual_file_name = this.props.prev_ws_manual_file_name;
            params.ws_id = this.props.ws_id;
            params.wsg_rev = this.props.wsg_rev;
          }
          if (isGuideManualLocation) {
            params.wsg_manual_id = this.props.wsg_manual_id;
            params.replace = this.props.replace;
            params.wsg_rev = this.props.wsg_rev;
          }
          if (isCustomWoLocation) {
            params.wsg_rev = this.props.wsg_rev;
          }

          // Set IFU Upload to pending
          if (isIfuUploadFolder || isDigiBpFolder) {
            this.props.setIfuUploadPending();
          }
        }

        try {
          const currentUpload = await FilesServiceInstance.uploadFile(file, query, params);

          const isAbleToSave = FilesServiceInstance.isUploadOk(currentUpload);
          if (isAbleToSave) {
            uploads.push(currentUpload);
          }
          areAllFilesUploaded = uploads.length === files.length;

          if (isDropboxUsed && areAllFilesUploaded) {
            if (isAbleToSave) {
              this.clearIfuDdmPending();
            } else if (uploaderWarnEl) {
              uploaderWarnEl.classList.remove('hide');
            }
          }
        } catch (error) {
          console.error(error);
          handleHttpRequestError(error, this);

          if (!isDropboxUsed) {
            resetUploadingState();
          }
        }
      }

      if (areAllFilesUploaded) {
        if (!isScansFolder && uploaderEl) {
          uploaderEl.classList.add('hide-animation');
        }

        this.props.onUpload(uploads);

        if (this.props.onIncompleteSave) {
          this.props.onIncompleteSave('', this.props.folder);
        }

        resetUploadingState();
      }
    }
  }

  async onDownload(event) {
    setTokenHeader();
    event.preventDefault();
    let href = event.currentTarget.href;
    let startingPath = href.indexOf('/', 9);
    let endPath = href.lastIndexOf('_');

    if (startingPath >= 0 && endPath >= 0) {
      const filePath = href.substring(startingPath, href.length);
      const link = await FilesServiceInstance.getDownloadLink(filePath);
      WindowUtils.openInNewWindow(link);
    }
  }

  removeElement = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.parentNode.removeChild(element);
    }
  };

  onThumbnailLoad(url, id) {
    setTokenHeader();
    Axios({
      url: url,
      method: 'GET',
      responseType: 'blob', // important
    })
      .then((response) => {
        // Have to be very careful since this is an async call and the user may not
        // be on this page when we get the returned response
        let url = '';
        if (response.data.size !== 0) {
          url = window.URL.createObjectURL(new Blob([response.data]));
        } else {
          url = '/static/img/no-image.png';
        }

        if (document.querySelector(`#_${id}`)) {
          document.querySelector(`#_${id}`).src = url;
          document.querySelector(`#_${id}`).classList.add('image-fixed-size');
          document.querySelector(`#_${id}`).classList.remove('img-hide');
          this.removeElement(`loader_${id}`);
        }

        if (document.querySelector(`[src=_${id}]`)) {
          document.querySelector(`[src=_${id}]`).src = url;
          document.querySelector(`[src=_${id}]`).classList.add('image-fixed-size');
        }
      })
      .catch(function (error) {
        console.log(error);
        if (document.querySelector(`#_${id}`)) {
          document.querySelector(`#_${id}`).src = '/static/img/no-image.png';
        }
      });
  }

  generateId(url) {
    return uuidv3(url, uuidv3.URL);
  }

  getFileName(path) {
    let result = path;
    let index = path.lastIndexOf('/');

    if (index >= 0) {
      result = path.substring(index + 1, path.length);
    }

    return result;
  }

  /**
   * Determines if provided file is of a specific file type
   * @function
   * @param {list} fileUploaded - Uploaded file
   * @param {string} file_type - File type
   * @returns {boolean} - True or false
   */
  isFileType = (fileUploaded, file_type) => {
    let that = this;
    let result = false;
    _.each(fileUploaded, function (file) {
      if (file.file_type === that.props.folder && file.file_type === file_type) {
        result = true;
      }
    });

    return result;
  };

  isSetupFile(fileUploaded) {
    let that = this;
    let result = false;
    _.each(fileUploaded, function (file) {
      if (file.file_type === that.props.folder && (file.file_type === 'setup' || file.file_type === 'adjusted_setup' || file.file_type === 'final_design')) {
        result = true;
      }
    });

    return result;
  }

  isSTLandhasFile(fileUploaded) {
    //Check if this is upperscans or lowerscans
    let that = this;
    let result = false;
    _.each(fileUploaded, function (file) {
      if (
        (file.file_type === that.props.folder && file.file_type === 'upperscans') ||
        (file.file_type === that.props.folder && file.file_type === 'lowerscans')
      ) {
        result = true;
      }
    });

    return result;
  }

  /**
   * Filter record states
   * @function
   */
  filterRecordStates = () => {
    if (this.props.record_states && this.props.folder === 'photos') {
      return this.props.record_states.filter((file) => {
        return file.image === true;
      });
    } else if (this.props.record_states && this.props.folder === 'scans') {
      return this.props.record_states.filter((file) => {
        return file.scan === true;
      });
    } else {
      return this.props.record_states;
    }
  };
  /**
   * Handle file remove
   * @param {object} event - Delete event info
   * @function
   */
  onRemove = (event) => {
    const file_id = event.currentTarget.dataset.file_id;
    this.props.updateDeleteInProcess(this.props.record_states, file_id);
    this.props.onRemove(event);
  };
  /**
   * Display view type after file/content upload
   * @function
   */
  displayUploadedView = () => {
    const is_business = window.location.href.includes('business');
    return is_business ? this.displayFileView() : this.displayRecordView();
  };
  /**
   * Display file view
   * @function
   */
  displayFileView = () => {
    const { folder, filesUploaded, removeAllowed, disabled, onRemove } = this.props;
    const className = folderToClassNamesMap[folder] || 'center-text';
    const isIconHidden = Array.isArray(filesUploaded) && !filesUploaded.some((f) => f.hideDeleteIcon);
    if (!(filesUploaded && filesUploaded.length > 0 && folder !== 'repaired_scans')) {
      return null;
    }
    return (
      <div className={`fileUpload-previews${folder === 'ifu_upload' ? ' fileUpload-previews__ifu' : ''}`}>
        {filesUploaded
          .filter((file) => file.file_type.indexOf(folder) >= 0)
          .map((file, index) => {
            return (
              <div className={className} key={file.upload_data}>
                <div className={this.isThreeMaticFile(file) ? 'adjusted-image-box' : 'image-box'}>
                  <div className="div-padding-b2">
                    {folder === 'upperscans' || folder === 'lowerscans' || folder === 'scans' ? (
                      file.file_type === 'upperscans' ? (
                        <img className="img-preview fs-exclude" src={process.env.PUBLIC_URL + '/static/img/upper.png'} alt="upper" />
                      ) : file.file_type === 'lowerscans' ? (
                        <img className="img-preview fs-exclude" src={process.env.PUBLIC_URL + '/static/img/lower.png'} alt="lower" />
                      ) : (
                        <div className="container-image" data-index={index} onClick={this.props.location === 'smilesimulation' ? null : this.onThumbnailClick}>
                          <img
                            className={this.props.location === 'smilesimulation' ? 'img-preview ignore-thumbnail fs-exclude' : 'img-preview fs-exclude'}
                            src={process.env.PUBLIC_URL + '/static/img/model.png'}
                            data-index={index}
                            alt="teeth scan model"
                          />
                          <div className="image-text-centered">
                            {file.original_filename}
                            <br />
                            Uploaded
                          </div>
                        </div>
                      )
                    ) : this.props.folder === 'setup' || this.props.folder === 'adjusted_setup' || this.props.folder === 'final_design' ? (
                      <a href={'/' + file.upload_data} onClick={this.onDownload} download>
                        <img className="img-preview img-sm fs-exclude" src={process.env.PUBLIC_URL + '/static/img/setup.jpg'} alt="setup" />
                      </a>
                    ) : this.props.folder === 'ifu_upload' ||
                      this.props.folder === 'ddm_upload' ||
                      this.props.folder === 'digi_bp' ||
                      this.props.folder === 'single_tooth_idb_final' ? (
                      <a href={'/' + file.upload_data} onClick={this.onDownload} download>
                        <i className="fa fa-file-archive-o fa-5x img-ifu" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Download Zip" />
                      </a>
                    ) : file.upload_data.includes('.stl') ? (
                      <div key={index} className="container-image" data-index={index} onClick={this.onThumbnailClick}>
                        <img
                          className="img-preview fs-exclude"
                          src={process.env.PUBLIC_URL + '/static/img/model.png'}
                          data-index={index}
                          alt="teeth scan model"
                        />
                        <div className="image-text-centered">
                          {' '}
                          {file.original_filename}
                          <br />
                          Uploaded
                        </div>
                      </div>
                    ) : file.upload_data.includes('.xlsx') || file.upload_data.includes('.xls') || file.upload_data.includes('.csv') ? (
                      <a href={'/' + file.upload_data} onClick={this.onDownload} download>
                        <div className="img-excel">
                          <FontAwesomeIcon icon={['fa', 'file-excel']} />
                        </div>
                      </a>
                    ) : (
                      <div className="loader-holder">
                        <CircleLoader id={'loader_' + this.generateId(file.upload_data)} />
                        <img
                          id={'_' + this.generateId(file.upload_data)}
                          className="img-hide img-preview fs-exclude"
                          src={process.env.PUBLIC_URL + file.upload_data.includes('.stl') ? '/static/img/setup.jpg' : '/static/img/no-image.png'}
                          onLoad={this.onThumbnailLoad(`/api/thumbnail/?file=${file.upload_data}&size=lrg`, this.generateId(file.upload_data))}
                          data-index={index}
                          onClick={this.onThumbnailClick}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                  {removeAllowed && !disabled ? (
                    <div className={this.isThreeMaticFile(file) ? 'remove-location-ifu' : 'remove-location 2'}>
                      <BootstrapTooltip title="Delete" placement="top" arrow>
                        <a
                          href={'/' + file.upload_data}
                          onClick={onRemove}
                          onDoubleClick={onRemove}
                          data-type={folder}
                          data-file_id={file.incomplete_case_file_id}
                          data-href={'/' + file.upload_data}
                        >
                          {this.displayRemoveIconByType(file, isIconHidden)}
                        </a>
                      </BootstrapTooltip>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  /**
   * Display remove icon base on file type
   * @function
   * @param {Object} file - Object containing file information
   * @param {Boolean} notHidingIcon - Should hide icon or no
   * @return {JSX} JSX for remove icon
   */
  displayRemoveIconByType = (file, notHidingIcon) => {
    if (file) {
      let icon = '';
      const trash_icon_type_1 = ['fas', 'trash'];
      const trash_icon_type_2 = ['fas', 'circle-xmark'];

      if ((icon = isThreeMaticFile(file) || ['ip_upload', 'setup', 'final_design'].includes(file.file_type))) {
        icon = trash_icon_type_1;
      } else icon = trash_icon_type_2;

      if (file.file_type == 'adjusted_setup') {
        return (
          <i
            style={{ display: file.hideDeleteIcon ? 'none' : 'block', pointerEvents: notHidingIcon ? 'auto' : 'none' }}
            className="fa fa-trash-o remove-adjusted-setup rejected-text font-size-lg"
            aria-hidden="true"
          />
        );
      }

      if (icon === trash_icon_type_1) {
        return (
          <i
            style={{ display: file.hideDeleteIcon ? 'none' : 'block', pointerEvents: notHidingIcon ? 'auto' : 'none' }}
            className={`fa fa-trash-o icon--warning file-upload-remove rejected-text font-size-lg ${!isThreeMaticFile(file) && 'white-bg'}`}
            aria-hidden="true"
          />
        );
      }

      return (
        <FontAwesomeIcon
          style={{ display: file.hideDeleteIcon ? 'none' : 'block', pointerEvents: notHidingIcon ? 'auto' : 'none' }}
          icon={icon}
          className={`icon--warning file-upload-remove rejected-text font-size-lg ${!isThreeMaticFile(file) && 'white-bg'}`}
        />
      );
    }
  };
  /**
   * Display record view
   * @function
   */
  displayRecordView = () => {
    return (
      <RecordViewer
        records={this.filterRecordStates()}
        subtitle={this.props.recordViewerSubtitle}
        showRemoveIteroScans={this.props.showRemoveIteroScans}
        onRemoveIteroScansClick={this.props.onRemoveIteroScansClick}
        showRemoveIteroPhotos={this.props.showRemoveIteroPhotos}
        onRemoveIteroPhotosClick={this.props.onRemoveIteroPhotosClick}
        onLoad={this.props.onLoadImageRecord}
        onRemove={this.onRemove}
        removeAllowed={this.props.removeAllowed}
        disabled={this.props.disabled}
        isLoadingIteroPhotos={this.props.isLoadingIteroPhotos}
        isDeletingIteroPhotos={this.props.isDeletingIteroPhotos}
        onClick={(id, action) => {
          onImageToolAction(id, action, this.props);
        }}
      ></RecordViewer>
    );
  };

  /**
   * Get dropzone class name
   * @function
   * @return {string} class name for dropzone component
   */
  getDropZoneClassName = () => {
    const class_name =
      this.props.type === 'segmentation_upload' || this.props.type === 'repaired_scans'
        ? 'center-text bpp-fileUpload-box-segmentation'
        : this.props.theme === 'bpp'
        ? 'center-text bpp-fileUpload-box'
        : this.props.type === 'setup' ||
          this.props.type === 'ip_upload' ||
          this.props.type === 'adjusted_setup' ||
          this.props.type === 'ifu_upload' ||
          (this.props.type === 'ddm_upload' && !this.isLargeDdmDropzone()) ||
          this.props.type === 'segmentation_upload' ||
          this.props.type === 'final_design'
        ? `center-text fileUpload-box-sm ${this.props.className}`
        : this.props.type === 'additional' || (this.props.type === 'ddm_upload' && this.isLargeDdmDropzone()) || this.props.type === 'single_tooth_idb_final'
        ? 'center-text fileUpload-box-full'
        : 'center-text fileUpload-box';
    return class_name;
  };

  /**
   * Get allowed file types based on types
   * @function
   * @return {string} Allowed file based on upload types
   */
  getAllowedFileType = () => {
    switch (this.props.type) {
      case 'both':
      case 'progress_records':
        return '.stl, model/stl, application/sla, application/vnd.ms-pki.stl, application/vnd.ms-pkistl, application/x-navistyle, application/netfabb, application/octet-stream, model/x.stl-binary, model/x.stl-ascii, application/vnd.epson.salt, application/wavefront-stl, application/vnd.ms-pki.stl, application/x-navistyle, image/jpeg, image/jpg, image/png, image/bmp,  image/tiff, image/gif';
      case 'model':
      case 'repaired_scans':
        return '.stl, model/stl, application/sla, application/vnd.ms-pki.stl, application/vnd.ms-pkistl, application/x-navistyle, application/netfabb, application/octet-stream, model/x.stl-binary, model/x.stl-ascii, application/vnd.epson.salt, application/wavefront-stl, application/vnd.ms-pki.stl, application/x-navistyle';
      case 'setup':
      case 'adjusted_setup':
      case 'ifu_upload':
      case 'ddm_upload':
      case 'final_design':
      case 'single_tooth_idb_final':
        return 'application/zip, application/x-zip-compressed, multipart/x-zip, .zip';
      case 'ip_upload':
        return 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.macroEnabled.12, .xls, .xlsx, .xlsm';
      case 'additional':
      case 'ws_guide':
      case 'ws_custom_wo':
        return 'application/pdf';
      case 'ws_dxf':
        return '.dxf, application/dxf, image/vnd.dwg, image/x-dwg';
      case 'navigator':
        return '.stl, model/stl, application/sla, application/vnd.ms-pki.stl, application/vnd.ms-pkistl, application/x-navistyle, application/netfabb, application/octet-stream, model/x.stl-binary, model/x.stl-ascii, application/vnd.epson.salt, application/wavefront-stl, application/vnd.ms-pki.stl, application/x-navistyle, image/jpeg, image/jpg, image/png';
      default:
        return 'image/jpeg, image/jpg, image/png, image/bmp, image/tiff, image/gif';
    }
  };
  render() {
    const { isDropRejected } = this.state;
    const uploadIcon = <LogOutIcon width="50" style={{ transform: 'rotate(-90deg)' }} />;
    return (
      <div className={`fileUpload-container ${this.props.withIteroSelection ? 'with-itero-selection' : ''}`}>
        <div className="file-upload-zone-container">
          {this.props.withIteroSelection ? (
            <UploadMethodSelector
              uploadMethod={this.props.uploadMethod}
              onChange={this.props.onUploadMethodChange}
              disabledEdit={this.props.disabled}
              title={this.props.type === 'progress_records' ? 'Select upload method. You may upload from both iTero and manually.' : 'Select upload method'}
            />
          ) : null}

          {this.props.iteroPhotosDropzoneMessage ? (
            <AlertContainer className={'itero-photos-dropzone-message'}>{this.props.iteroPhotosDropzoneMessage}</AlertContainer>
          ) : null}

          {this.props.showDropzone ? (
            this.isSTLandhasFile(this.props.filesUploaded) ||
            this.isSetupFile(this.props.filesUploaded) ||
            this.isFileType(this.props.filesUploaded, 'ddm_upload') ||
            this.isFileType(this.props.filesUploaded, 'ifu_upload') ||
            this.isFileType(this.props.filesUploaded, 'digi_bp') ||
            this.isFileType(this.props.filesUploaded, 'ip_upload') ||
            this.isFileType(this.props.filesUploaded, 'single_tooth_idb_final') ? (
              this.isSetupFile(this.props.fileUploaded) ? (
                <div>Setup Uploaded</div>
              ) : (
                ' '
              )
            ) : (
              <Dropzone
                className={this.getDropZoneClassName()}
                disabled={this.props.disabled || this.state.uploading || this.props.isLoadingIteroPhotos || this.props.isDeletingIteroPhotos}
                multiple={this.props.multiple ? this.props.multiple : false}
                onDrop={this.onDrop}
                accept={this.getAllowedFileType()}
                onDropRejected={this.handleDropReject}
                onMouseLeave={() => this.setState({ isDropRejected: false })}
              >
                {({ isDragActive, isDragAccept, isDragReject, draggedFiles, acceptedFiles, rejectedFiles }) => {
                  if (isDragAccept) {
                    if (this.state.files.length !== 0) {
                      return (
                        <div className="fileUpload-content">
                          <i className="rejected-text font-size-xlg" aria-hidden="true" />
                          <div className="accepted-text font-size-lg">Sorry, another upload is in process</div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="fileUpload-content">
                          <i className="fa fa-cloud-upload accepted-text font-size-xlg" aria-hidden="true" />
                          <div className="accepted-text font-size-lg">Upload File</div>
                        </div>
                      );
                    }
                  }

                  if (
                    (isDragReject || isDropRejected) &&
                    this.props.type !== 'model' &&
                    this.props.type !== 'both' &&
                    this.props.type !== 'setup' &&
                    this.props.type !== 'adjusted_setup' &&
                    this.props.type !== 'inbrace_ifs' &&
                    this.props.type !== 'ip_upload' &&
                    this.props.type !== 'progress_records' &&
                    this.props.type !== 'segmentation_upload' &&
                    this.props.type !== 'repaired_scans' &&
                    this.props.type !== 'ws_dxf'
                  ) {
                    return (
                      <div className="fileUpload-content">
                        <i className="fa fa-ban rejected-text font-size-xlg" aria-hidden="true" />
                        <div className="rejected-text font-size-lg">File extension not allowed</div>
                      </div>
                    );
                  } else if ((isDragReject || isDropRejected) && this.props.type === 'model') {
                    if (this.state.uploading || (this.props.upload_content && this.props.upload_content.length > 0)) {
                      return (
                        <div className="fileUpload-content">
                          <i className="rejected-text font-size-xlg" aria-hidden="true" />
                          <div className="accepted-text font-size-lg">Sorry, another upload is in process</div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="fileUpload-content">
                          <i className="fa fa-cloud-upload accepted-text font-size-xlg" aria-hidden="true" />
                          <div className="accepted-text font-size-lg">
                            Upload{' '}
                            {this.props.folder === 'upperscans' ? ' Upper (Maxillar) ' : this.props.folder === 'lowerscans' ? ' Lower (Mandibular) ' : ''} File
                            (.stl only)
                          </div>
                        </div>
                      );
                    }
                  } else if ((isDragReject || isDropRejected) && (this.props.type === 'both' || this.props.type === 'progress_records')) {
                    return (
                      <div className="fileUpload-content">
                        <i className="fa fa-cloud-upload accepted-text font-size-xlg" aria-hidden="true" />
                        <div className="accepted-text font-size-lg">Upload File (.stl, .png, .jpeg, .jpg, .bmp only)</div>
                      </div>
                    );
                  } else if (
                    (isDragReject || isDropRejected) &&
                    (this.props.type === 'setup' ||
                      this.props.type === 'adjusted_setup' ||
                      this.props.type === 'inbrace_ifs' ||
                      this.props.type === 'ifu_upload' ||
                      this.props.type === 'ddm_upload' ||
                      this.props.type === 'digi_bp')
                  ) {
                    return (
                      <div className="fileUpload-content">
                        <i className="fa fa-cloud-upload accepted-text font-size-xlg" aria-hidden="true" />
                        <div className="accepted-text font-size-lg">Upload File (.zip)</div>
                      </div>
                    );
                  } else if ((isDragReject || isDropRejected) && this.props.type === 'ip_upload') {
                    return (
                      <div className="fileUpload-content">
                        <i className="fa fa-cloud-upload accepted-text font-size-xlg" aria-hidden="true" />
                        <div className="accepted-text font-size-lg">Upload File (.xls/.xlsx/.xlsm)</div>
                      </div>
                    );
                  }

                  if (this.props.upload_content && this.props.upload_content.length > 0 && this.props.folder !== 'repaired_scans') {
                    return (
                      <div className="fileUpload-content">
                        <div className="accepted-text font-size-lg">
                          {this.props.upload_content.map((file, index) => {
                            return (
                              <div className="loading_text" key={uuidv4()}>
                                <span className="loading-file-name" key={uuidv4()}>
                                  {file.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                        <CircleLoader />
                      </div>
                    );
                  } else if (this.props.isDeletingIteroPhotos) {
                    return (
                      <div className="fileUpload-content">
                        <div className="accepted-text font-size-lg">Removing Photos from iTero</div>
                        <CircleLoader />
                      </div>
                    );
                  } else if (this.props.isLoadingIteroPhotos) {
                    return (
                      <div className="fileUpload-content">
                        <div className="accepted-text font-size-lg">Loading Photos from iTero</div>
                        <CircleLoader />
                      </div>
                    );
                  }

                  if (
                    (acceptedFiles.length && this.state.accepted && this.state.files && this.state.files.length > 0) ||
                    (this.props.ddmPending && this.props.folder === 'ddm_upload') ||
                    (this.props.ifuUploadPending && this.props.folder === 'ifu_upload') ||
                    (this.props.ifuUploadPending && this.props.folder === 'digi_bp')
                  ) {
                    return (
                      <div className={'fileUpload-content' + (this.props.type === 'ifu_upload' || this.props.type === 'ddm_upload' ? ' ifu' : '')}>
                        <div className="accepted-text font-size-lg">
                          {this.state.files.map((file, index) => {
                            return <div key={index}>{file.name}</div>;
                          })}
                          {!this.state.files.length && this.props.type === 'ifu_upload' && this.props.ifuFileName && <div>{this.props.ifuFileName}</div>}
                          {!this.state.files.length && this.props.type === 'ddm_upload' && this.props.ddmFileName && <div>{this.props.ddmFileName}</div>}
                        </div>
                        <CircleLoader />
                      </div>
                    );
                  }

                  if (this.props.type === 'model') {
                    return (
                      <div className="fileUpload-content">
                        <div className="fileUpload-content-left">
                          {this.props.text && this.props.text === 'de' ? (
                            <div>
                              Upload patient Toothprints. Leave the Smartwire ligated to brackets when taking the DE Toothprints.
                              <ul>
                                <li>Maxillary arch STL file</li>
                                <li>Mandibular arch STL file</li>
                              </ul>
                            </div>
                          ) : (
                            <div>
                              Upload patient Toothprints.
                              <ul>
                                <li>Maxillary arch STL file</li>
                                <li>Mandibular arch STL file</li>
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="upload-button">{uploadIcon}</div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  } else if (this.props.type === 'repaired_scans') {
                    return (
                      <div className="fileUpload-content">
                        <div className="fileUpload-content-left">
                          {this.props.text && this.props.text === 'de' ? (
                            <div>
                              Please upload the repaired scans (oriented in occlusion):
                              <ul>
                                <li>Upper arch STL</li>
                                <li>Lower arch STL</li>
                              </ul>
                            </div>
                          ) : (
                            <div>
                              Please upload the repaired scans (oriented in occlusion):
                              <ul>
                                <li>Upper arch STL</li>
                                <li>Lower arch STL</li>
                              </ul>
                            </div>
                          )}
                        </div>
                        <div>
                          <i className="fa fa-upload font-size-lg" aria-hidden="true" />
                        </div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  } else if (this.props.type === 'both') {
                    return (
                      <div className="fileUpload-content">
                        <div className="grey-text font-size-lg">
                          Drag file(s) here <br /> or
                        </div>
                        <div className="fileUpload-button">Select file(s) from folder</div>
                      </div>
                    );
                  } else if (this.props.type === 'setup' || this.props.type === 'adjusted_setup' || this.props.type === 'final_design') {
                    return (
                      <div className="fileUpload-content">
                        <div className="font-size-reg">
                          {this.props.isSmile ? 'Drag and drop or click here to upload Smile Simulation' : 'Drag and drop or click here to upload Smile Design'}
                        </div>
                        <i className="fa fa-upload font-size-lg" aria-hidden="true" />
                      </div>
                    );
                  } else if (this.props.type === 'ifu_upload' || (this.props.type === 'ddm_upload' && !this.isLargeDdmDropzone())) {
                    return (
                      <div className="ifu fileUpload-content" id={this.props.type.replace('_', '-')}>
                        <div className="font-size-reg">Drag and drop or click here to upload file</div>
                        <i className="fa fa-upload font-size-40" aria-hidden="true" />
                      </div>
                    );
                  } else if (this.props.type === 'ip_upload') {
                    return (
                      <div className="fileUpload-content">
                        <div className="font-size-reg">Drag and drop or click here to upload file</div>
                        <i className="fa fa-upload font-size-lg" aria-hidden="true" />
                      </div>
                    );
                  } else if (this.props.type === 'additional') {
                    return (
                      <div className="fileUpload-content">
                        <div className="grey-text font-size-lg">
                          Drag pdf here
                          <br />
                          or
                        </div>
                        <div className="btn btn-light-3">Select pdf from folder</div>
                      </div>
                    );
                  } else if (['ddm_upload', 'single_tooth_idb_final'].includes(this.props.type)) {
                    return (
                      <div className="fileUpload-content">
                        <div className="grey-text font-size-lg">
                          Drag zip here
                          <br />
                          or
                          <br />
                        </div>
                        <div className="btn btn-light-3">Select zip from folder</div>
                        <p className="error idb-error">{this.state.idb_error}</p>
                      </div>
                    );
                  } else if (this.props.folder === 'xrays') {
                    return (
                      <div className="fileUpload-content">
                        <p className="dark-text">Please upload Panoramic radiographs of your patient. Lateral Ceph is optional.</p>
                        <div className="upload-button">{uploadIcon}</div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  } else if (this.props.folder === 'progress_records') {
                    return (
                      <div className="fileUpload-content">
                        <p>Upload high resolution intraoral photos in ABO format when possible and, if available, upload current Toothprints.</p>
                        <div className="upload-button">{uploadIcon}</div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  } else if (this.props.folder === 'ws') {
                    return (
                      <div className="fileUpload-content">
                        <p>{`Please upload a ${this.props.type === 'ws_dxf' ? 'dxf' : 'pdf'} file.`}</p>
                        <div className="upload-button">{uploadIcon}</div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  } else if (this.props.folder === 'navigator') {
                    return (
                      <div className="fileUpload-content">
                        <p>Please upload high resolution final photos in ABO format when possible, and final intra-oral scan if available.</p>
                        <div className="upload-button">{uploadIcon}</div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  } else {
                    return (
                      <div className="fileUpload-content">
                        <p>Please upload a composite photo of your patient or equivalent individual images.</p>
                        <div className="upload-button">{uploadIcon}</div>
                        <span className="emphasis">
                          Drag and drop or <span className="midnight">browse</span>
                        </span>
                      </div>
                    );
                  }
                }}
              </Dropzone>
            )
          ) : this.props.showIteroSelection ? (
            // iTero integration
            <IteroSelection
              caseId={this.props.id}
              disabledEdit={this.props.disabled}
              isLoadingIteroPhotos={this.props.isLoadingIteroPhotos}
              isDeletingIteroPhotos={this.props.isDeletingIteroPhotos}
              isBuildingIteroScansRecords={this.props.isBuildingIteroScansRecords}
              {...this.props.iteroSelectionProps}
            />
          ) : null}
        </div>
        <div id="fileUpload-warning" className="fileUpload-warning hide">
          The file uploaded may be corrupted or is not a supported format. Please convert your file to one of the supported file format.
        </div>
        {this.displayUploadedView()}

        <Lightbox
          images={this.state.images}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this.gotoPrev}
          onClickNext={this.gotoNext}
          currentImage={this.state.lightboxCurrent}
          onClose={this.closeLightbox}
        />
        {this.state.refresh && <SessionExpire />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ddmPending: getPending(state),
    ifuUploadPending: getUploadPending(state),
    ddmFileName: getDdmFileName(state),
    ifuFileName: getIfuFileName(state),
    ifuState: getIfuState(state),
    ddmState: getDdmState(state),
    ifuFolder: getIfuFolder(state),
    record_states: getRecordStates(state),
    ws_wo_history: getWsWoHistory(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDdmFileName: setDdmFileName,
      setIfuUploadPending: setUploadPending,
      setIfuFileName: setIfuFileName,
      postIfuCaseFileSuccess: postIfuCaseFileSuccess,
      uploadDdmInputFileSuccess: uploadDdmInputFileSuccess,
      uploadDdmInputFilePending: uploadDdmInputFilePending,
      setIFUIncorrectFileNameError: setIFUIncorrectFileNameError,
      setDDMIncorrectFileNameError: setDDMIncorrectFileNameError,
      setDdmFileTypeError: setDdmFileTypeError,
      setIfuFileTypeError: setIfuFileTypeError,
      clearDdmFileTypeError: clearDdmFileTypeError,
      clearIfuFileTypeError: clearIfuFileTypeError,
      setDxfFileNameError: setDxfFileNameError,
      setIfuFolder: setIfuFolder,
      setWsGuideFileNameError: setWsGuideFileNameError,
      // For Records Viewer
      buildRecordStates: buildRecordStates,
      onLoadImageRecord: onLoadImageRecord,
      onRecordViewerAction: onRecordViewerAction,
      updateDeleteInProcess: updateDeleteInProcess,
      setCustomWoFileNameError: setCustomWoFileNameError,
    },
    dispatch
  );

const FileUploadConnected = connect(mapStateToProps, mapDispatchToProps)(FileUpload);
export default FileUploadConnected;
