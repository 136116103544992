import React, { Component } from 'react';
import FileUpload from '../../components/file_upload/file_upload';

class Uploader extends Component {
  render() {
    return (
      <div className={`uploader-container ${this.props.containerClassName}`}>
        <form>
          <FileUpload
            className={this.props.className}
            id={this.props.id}
            filesUploaded={this.props.photoUpload}
            onUpload={this.props.onUpload}
            onRemove={this.props.onRemove}
            multiple={this.props.multiple}
            location={this.props.location ? this.props.location : 'incomplete'}
            folder={this.props.folder}
            type={this.props.type}
            removeAllowed={this.props.remove_btn}
            isUploading={this.props.isUploading}
            isSmile={this.props.isSmile}
            wire_name={this.props.wire_name}
            prev_ws_manual_file_name={this.props.prev_ws_manual_file_name}
            ws_id={this.props.ws_id}
            show_warning={this.props.show_warning}
            disabled={this.props.disabledEdit}
            replace={this.props.replace}
            wsg_rev={this.props.wsg_rev}
            wsg_manual_id={this.props.wsg_manual_id}
            gen_2={this.props.gen_2}
            ws_version={this.props.ws_version}
          />
        </form>
      </div>
    );
  }
}

export default Uploader;
