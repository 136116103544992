// External
import React from 'react';

// Internal
import './wire_fabrication_review.scss';
import { sortWires, getSmartwireNum } from '../wire_selection/wire_selection_review_confirm';

/**
 * Translates wire name for display
 * @function
 * @param {object} wire - Wire object
 * @returns {string} - Translated name
 */
const translateWireName = (wire) => {
  const is_upper = wire.wire_name.indexOf('upper') >= 0;
  const smartwire_num = getSmartwireNum(wire);

  return `${is_upper ? 'Upper' : 'Lower'} Smartwire ${smartwire_num} (${getWireType(wire)})`;
};

/**
 * Retrieves wire type
 * @function
 * @param {object} wire - Wire object
 * @returns {string} - Wire type (template, auto custom, or manual custom)
 */
const getWireType = (wire) => {
  const verbiage = { template: 'Template', automated_custom: 'Auto Custom', manual_custom: 'Manual Custom', universal_template: 'Universal Template' };
  const selection = wire.selection_override ? wire.selection_override : wire.selection;
  return selection ? verbiage[selection] : '';
};

/**
 * Retrieves formatted text for selected break location (displayed on modal)
 * @function
 * @param {object} break_location - Break location selection info
 * @returns {string} - Formatted break location text
 */
const getSelectedBreakLocationText = (break_location) => {
  const location = break_location.location;
  if (location && !Object.values(location).some((val) => !val)) {
    return `${location.arch_1}${location.tooth_1} to ${location.arch_2}${location.tooth_2}`;
  }
  return 'N/A';
};

/**
 * Component for wire fabrication review summary of wire failures
 * @function
 * @param {object} props - Props object
 * @returns {JSX} - Wire fabrication review summary component
 */
function WireFabricationReviewSummary(props) {
  const wires = sortWires(props.failed_wires);

  /**
   * Displays break location selection on modal
   * @function
   * @param {object} break_location - Break location selection info
   * @param {number} bl_index - Break location index
   * @param {object} wire - Wire info
   * @returns {JSX} - Break location
   */
  const displayBreakLocation = (break_location, bl_index, wire) => {
    return (
      <div key={bl_index}>
        <div className="wire-failure-summary__row wire-failure-summary__row__inner">
          <div>Break Location</div>
          <div>{getSelectedBreakLocationText(break_location)}</div>
        </div>
        <div className="wire-failure-summary__row wire-failure-summary__row__inner">
          <div>Process Step</div>
          <div>{break_location.process_step ? break_location.process_step : 'N/A'}</div>
        </div>
        {bl_index !== wire.comment.break_locations.length - 1 && <div className="bl-separator-modal" />}
      </div>
    );
  };

  return (
    <div className={`wire-failure-summary${props.is_log ? ' wire-failure-summary__log' : ''}`}>
      {wires.map((wire, wire_index) => {
        return (
          <div key={wire_index}>
            <div className="wire-failure-summary__row">
              <div className="wire-label">
                {wire_index + 1}. {translateWireName(wire)}
              </div>
              {!props.is_log && <div className="failure-label">Failure</div>}
            </div>
            <div className="wire-failure-summary__row wire-failure-summary__row__inner">
              <div>Course of Action</div>
              <div>{wire.comment.course_of_action ? wire.comment.course_of_action : 'N/A'}</div>
            </div>
            <div className="bl-separator-modal" />
            {wire.comment && wire.comment.break_locations && (
              <>
                {wire.comment.break_locations.map((break_location, bl_index) => {
                  return displayBreakLocation(break_location, bl_index, wire);
                })}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export { translateWireName, getWireType, getSelectedBreakLocationText };
export default WireFabricationReviewSummary;
