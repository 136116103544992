/**
 * File:ir_content.js - Component to list content of item request list
 * Copyright: (c) Copyright July 2019 by InBrace
 * Authors: Ravi Gosai
 * Project: Inbrace Portal
 * Special Notes: NA
 **/
// ---------------------------------- Imports ----------------------------------
// Css
import './ir_list.scss';
import '../../components/loader/loader.scss';
// External Libs
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import Axios from 'axios';
import PaginationContainer from '../../components/pagination/pagination_container';
import { Helmet } from 'react-helmet';

// Internal Components
import Loader from '../../components/loader/loader';
import NotFound from '../../doctor/404/not_found';
import SortHeader from '../../components/layout/sort_header';
import ContextMenu from '../../components/context_menu/context_menu';
import NewTabLink from '../../components/context_menu/new_tab_link';

// Internal Functions
import { setTokenHeader, computeDaysPast, convertDate, removeIreqIdInitialNumber } from '../../common/functions';
import { handleHttpRequestError } from '../../common/error';
import {
  getBusinessSearchValueFromRoute,
  getBusinessFilterValueFromRoute,
  getBusinessColumnValueFromRoute,
  getBusinessStatusValueFromRoute,
  getBusinessStartDateValueFromRoute,
  getBusinessEndDateValueFromRoute,
} from '../../common/route';
import { translateStatus, getSubmissionDateDefaultSort, getIrStatusPriorityDefinition } from '../../common/helpers';
import {
  onChangeSearch,
  applyItemRequestSearch,
  translateFilterStatus,
  getItemRequestsFromRow,
  getItemRequestsFromSearch,
  getItemRequestsFromFilter,
  getItemRequestsFromEndDate,
  getItemRequestsFromStartDate,
  getItemRequestsFromStatus,
  orderItemRequestsByDefault,
  translateDate,
  loadConfigWithState,
  updateLoadConfiguration,
  searchErrorMessage,
} from '../../common/search';
import { tableRowLink } from '../../common/table';
import { new_branding_words } from '../../common/nomenclature';
import { getGen2Ir } from '../../common/gen2';
import CaseListSidebar from '../case_list/case_list_sidebar';
import CaseListFilter from '../case_list/case_list_filter';
import CaseListDropdown from '../case_list/case_list_dropdown';

class IRContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: false,
      search: '',
      filter: '',
      ir_raw: [],
      ir_view: [],
      all_ir_raw: [],
      ir_sort_method: 'created_date',
      ir_sort_order_ascending: {
        ireq_id: true,
        patient_name: true,
        doctor_name: true,
        created_date: false,
        treatment: true,
        status: true,
        status_date: false,
        doctor_action_date: true,
        doctor_new_record_date: true,
        doctor_setup_date: true,
        target_ship_date: true,
      },
      selected_ir_type: 'All Item Requests',
      ir_row_size: 25,
      ir_active_page: 1,
      ir_page_range: 5,
      ir_length: 1,
      is_bottom: false,
      column_filter: '',
      status_filter: '',
      start_date_filter: '',
      end_date_filter: '',
      side_collapse: false,
      user_id: '',
      submission_date_error: false,
      statuses: {
        inbrace_action: 0,
        checking: 0,
        prepare: 0,
        prepare_new: 0,
        prepare_release: 0,
        prepare_correction: 0,
        manufacture: 0,
        hold: 0,
        doctor_action: 0,
        new: 0,
        setup: 0,
        inbrace_active: 0,
        active_me: 0,
        ship: 0,
        cancel: 0,
        activeexp: 0,
        pendingexp: 0,
        review: 0,
      },
      gen2_ir_statuses: [],
      gen2_ir_raw: [],
      newTabUrl: '',
    };

    this.isFilterManufacturing = this.isFilterManufacturing.bind(this);
    this.isFilterNewUpload = this.isFilterNewUpload.bind(this);
    this.isFilterNewSetupUpload = this.isFilterNewSetupUpload.bind(this);

    this.onChangeSearchPatient = this.onChangeSearchPatient.bind(this);
    this.onPageChange = this.onPageChange.bind(this);

    this.onPatientClick = this.onPatientClick.bind(this);
    this.onSearchButtonClick = this.onSearchButtonClick.bind(this);
    this.onQueryFilterSelection = this.onQueryFilterSelection.bind(this);
    this.onStatusFilterSelection = this.onStatusFilterSelection.bind(this);
    this.onSideBarCollapseClick = this.onSideBarCollapseClick.bind(this);
    this.hasAssignTo = this.hasAssignTo.bind(this);
    this.getStartRange = this.getStartRange.bind(this);
    this.getEndRange = this.getEndRange.bind(this);
    this.onSortClick = this.onSortClick.bind(this);
    this.setRoute = this.setRoute.bind(this);
    this.irTable = React.createRef();
  }

  hasAssignTo() {
    return false;
  }

  onPageChange(active_page) {
    //Allow for page changes without when filters are not applied yet
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute();
    const ir_sort_method = this.state.ir_sort_method;

    let ir_view = filter
      ? getItemRequestsFromFilter(this.state.ir_raw, filter, this.state.user_id)
      : getItemRequestsFromSearch(
          getItemRequestsFromEndDate(getItemRequestsFromStartDate(getItemRequestsFromStatus(this.state.ir_raw, status), start_date), end_date),
          this.state.search,
          column
        );

    this.setState({
      ir_active_page: active_page,
      ir_view: getItemRequestsFromRow(
        this.setOrderByMethod(ir_view, ir_sort_method, this.state.ir_sort_order_ascending[ir_sort_method]),
        active_page,
        this.state.ir_row_size
      ),
    });
  }

  isFilterManufacturingWorkflow = () => {
    return (
      this.state.filter === 'manufacture' ||
      this.state.filter === 'cad_process' ||
      this.state.filter === 'cad_revision' ||
      this.state.filter === 'qc_approval' ||
      this.state.filter === 'activeexp' ||
      this.state.filter === 'pendingexp'
    );
  };

  componentDidMount() {
    setTokenHeader();
    const that = this;
    const search = getBusinessSearchValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = getBusinessStatusValueFromRoute();
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();

    let config = {
      rate: 50,
      offset: 0,
      limit: 50,
      total: 0,
      search: search,
      column: column,
      status: status,
      start_date: start_date,
      end_date: end_date,
      filter: filter,
    };

    Axios.get(`/apiV2/allitemrequests?offset=${config.offset}&limit=${config.limit}`)
      .then(function (res) {
        let ir_raw = that.createItemRequestArray(res.data.item_request);
        let gen2_ir_raw = getGen2Ir(ir_raw);
        const is_epp = res.data.is_epp;
        const ir_total = res.data.item_request_total;
        const ir_sort_method = that.getSortMethodByFilterSelection(that.state.filter, filter, that.state.ir_sort_method);
        const ir_view = filter
          ? getItemRequestsFromFilter(ir_raw, filter, res.data.user_id)
          : getItemRequestsFromSearch(
              getItemRequestsFromEndDate(getItemRequestsFromStartDate(getItemRequestsFromStatus(ir_raw, status), start_date), end_date),
              search,
              column
            );

        that.setState({
          loading: false,
          search: search,
          column_filter: column,
          status_filter: translateFilterStatus(status, filter),
          start_date_filter: start_date,
          end_date_filter: end_date,
          ir_raw: ir_raw,
          ir_view: that.setOrderByMethod(
            getItemRequestsFromRow(orderItemRequestsByDefault(that, ir_view), that.state.ir_active_page, that.state.ir_row_size),
            ir_sort_method,
            that.state.ir_sort_order_ascending[ir_sort_method]
          ),
          ir_length: ir_view.length,
          ir_sort_method: ir_sort_method,
          all_ir_raw: ir_raw,
          all_ir_view: ir_view,
          all_ir_length: ir_view.length,
          ir_total: ir_total,
          gen2_ir_raw: gen2_ir_raw,
          is_epp: is_epp,
        });

        config.total = ir_total;
        config.ir_sort_method = ir_sort_method;
        config.statuses = res.data.statuses;
        config = updateLoadConfiguration(config);
        that.progressivelyLoadIR(config);
      })
      .catch(function (err) {
        handleHttpRequestError(err, that);
      });

    this.updateAlerts();

    window.$('[data-toggle="tooltip"]').tooltip();
  }
  /**
   * Continue pull the rest of the item request data to prevent long load time
   *
   * @param {String} config - Configuration for the Progress Loading Content
   */
  progressivelyLoadIR(config) {
    const that = this;

    if (config.limit <= config.total || config.offset <= config.total) {
      Axios.get(`/apiV2/allitemrequests?offset=${config.offset}&limit=${config.limit}`).then(function (res) {
        let ir_raw = that.state.all_ir_raw.concat(that.createItemRequestArray(res.data.item_request));
        let gen2_ir_raw = getGen2Ir(ir_raw);
        let raw = that.state.selected_ir_type !== 'All Item Requests' ? gen2_ir_raw : ir_raw;
        let ir_sort_method = that.state.ir_sort_method !== config.ir_sort_method ? that.state.ir_sort_method : config.ir_sort_method;
        config.ir_sort_method = ir_sort_method;

        config = loadConfigWithState(that, config);

        const ir_view = config.filter
          ? getItemRequestsFromFilter(raw, config.filter, res.data.user_id)
          : getItemRequestsFromSearch(
              getItemRequestsFromEndDate(getItemRequestsFromStartDate(getItemRequestsFromStatus(raw, config.status), config.start_date), config.end_date),
              config.search,
              config.column
            );

        that.setState({
          ir_raw: raw,
          ir_view: that.setOrderByMethod(
            getItemRequestsFromRow(orderItemRequestsByDefault(that, ir_view), that.state.ir_active_page, that.state.ir_row_size),
            config.ir_sort_method,
            that.state.ir_sort_order_ascending[config.ir_sort_method]
          ),
          ir_length: ir_view.length,
          all_ir_raw: ir_raw,
          all_ir_view: that.setOrderByMethod(
            getItemRequestsFromRow(orderItemRequestsByDefault(that, ir_view), that.state.ir_active_page, that.state.ir_row_size),
            config.ir_sort_method,
            that.state.ir_sort_order_ascending[config.ir_sort_method]
          ),
          all_ir_length: ir_view.length,
          gen2_ir_raw: gen2_ir_raw,
        });

        config = updateLoadConfiguration(config);
        that.progressivelyLoadIR(config);
      });
    }
  }
  /**
   * Concatenate statuses count value together
   *
   * @param {Object} status - Contains the current status count values for item request
   * @param {Object} loaded_status - Contains the additional status count values for item request
   */
  addToStatuses = (status, loaded_status) => {
    let new_status = _.cloneDeep(status);

    for (const key in status) {
      new_status[key] = status[key] + loaded_status[key];
    }

    return new_status;
  };

  updateAlerts() {
    setTokenHeader();
    const that = this;

    Axios.get(`/apiV2/iralerts/`)
      .then(function (res) {
        that.setState({
          statuses: res.data.statuses,
          all_ir_statuses: res.data.statuses,
          gen2_ir_statuses: res.data.gen2_ir_statuses,
          user_id: res.data.user_id,
        });
      })
      .catch(function (err) {
        //ignore error for side nav
      });
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip('fixTitle');

    const that = this;

    const state_filter = this.state.filter;

    const search = getBusinessSearchValueFromRoute();
    const filter = getBusinessFilterValueFromRoute();
    const column = getBusinessColumnValueFromRoute();
    const status = this.state.status_filter;
    const start_date = getBusinessStartDateValueFromRoute();
    const end_date = getBusinessEndDateValueFromRoute();
    const refresh = this.state.refresh;
    const active_page = 1;
    const row_size = this.state.ir_row_size;
    let ir_view = [];
    if ((state_filter !== filter || refresh) && that.state.user_id) {
      if (!this.state.submission_date_error) {
        ir_view = filter
          ? getItemRequestsFromFilter(that.state.ir_raw, filter)
          : getItemRequestsFromSearch(
              getItemRequestsFromEndDate(
                getItemRequestsFromStartDate(getItemRequestsFromStatus(that.state.ir_raw, this.state.status_filter), start_date),
                end_date
              ),
              search,
              column
            );

        const ir_sort_method = that.getSortMethodByFilterSelection(state_filter, filter, that.state.ir_sort_method);

        that.setState({
          ir_view: getItemRequestsFromRow(
            that.setOrderByMethod(ir_view, ir_sort_method, that.state.ir_sort_order_ascending[ir_sort_method]),
            active_page,
            row_size
          ),
          ir_active_page: active_page,
          ir_length: ir_view.length,
          ir_sort_method: ir_sort_method,
          search: search,
          filter: filter,
          column_filter: column,
          status_filter: translateFilterStatus(status, filter),
          start_date_filter: start_date,
          end_date_filter: end_date,
          refresh: false,
        });
      }
    }

    let numCasesRaw = this.state.ir_raw.length;
    let numCases = this.state.ir_length;

    if (
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname.endsWith('item') &&
      !this.props.history.location.search &&
      numCases !== numCasesRaw &&
      !this.state.refresh
    ) {
      // Refresh route when cases header is clicked or cases icon is clicked
      this.refreshRoute();
    }
  }
  /**
   * Converts any item request meta data needed for sorting
   * @function
   * @param {Array} item_request_list - Array of Item request item to translate
   * @return {Array} List of updated item request
   */
  createItemRequestArray(item_request_list, cases) {
    for (let i = 0; i < item_request_list.length; i++) {
      item_request_list[i].status_priority = this.translateIrStatusPriority(item_request_list[i]);
      item_request_list[i].submission_date_priority = this.translateIrSubmissionPriority(item_request_list[i]);
      item_request_list[i].submission_date_val = this.translateIrubmissionDate(item_request_list[i]);
      item_request_list[i].ship_date_val = this.translateShipDate(item_request_list[i]);
    }
    return item_request_list;
  }

  /**
   * Converts ship date into a number used for sorting
   * @function
   * @param {Object} item_request - Item request item to translate
   * @return {Int} Numberic value of a date
   */
  translateShipDate = (item_request) => {
    return translateDate(item_request.ship_date);
  }

  translateIrubmissionDate = (item_request) => {
    return translateDate(item_request.created_date);
  };

  translateCaseSubmissionDateTimeZone = (item_request) => {
    return moment.tz(item_request.created_date, 'America/Los_Angeles').format();
  };

  translateIrStatusPriority(item_request) {
    const status_priority_definition = getIrStatusPriorityDefinition();
    const last_priority = 10;
    return status_priority_definition[item_request.ireq_status] ? status_priority_definition[item_request.ireq_status] : last_priority;
  }

  translateIrSubmissionPriority(item_request) {
    const date_in_milliseconds = new Date(item_request.created_date).getTime();
    return item_request.status_priority >= getIrStatusPriorityDefinition('Item Request Shipped') ? -date_in_milliseconds : date_in_milliseconds;
  }

  isFilterManufacturing = () => {
    const status = getBusinessStatusValueFromRoute();
    return (
      this.state.filter === 'prepare' ||
      status === 'prepare' ||
      this.state.filter === 'activeexp' ||
      status === 'activeexp' ||
      this.state.filter === 'pendingexp' ||
      status === 'pending'
    );
  };

  isFilterDoctorAction = () => {
    return this.state.filter === 'doctor_action';
  };

  isFilterNewUpload = () => {
    return this.state.filter === 'new';
  };

  isFilterNewSetupUpload = () => {
    return this.state.filter === 'setup';
  };

  onRedirectToLogin = () => {
    this.props.history.push(`/login`);
  };

  onPatientClick = (event) => {
    const pathname = `/business/portal/case/${event.currentTarget.dataset.caseid}/item`;
    tableRowLink(event, pathname, this.props);
  };

  getSortMethodByFilterSelection = (state_filter, filter, sort_method) => {
    let sort_method_update = sort_method;

    if (state_filter !== filter) {
      sort_method_update =
        filter === 'prepare'
          ? 'target_ship_date'
          : filter === 'new'
          ? 'created_date'
          : filter === 'setup'
          ? 'created_date'
          : filter === 'doctor_action'
          ? 'created_date'
          : filter === 'activeexp'
          ? 'target_ship_date'
          : filter === 'pendingexp'
          ? 'target_ship_date'
          : filter === 'late'
          ? 'target_ship_date'
          : 'created_date';
    }

    return sort_method_update;
  };

  getSortValue(sort) {
    return sort ? 'asc' : 'desc';
  }

  getStartRange() {
    return this.state.ir_active_page * this.state.ir_row_size - this.state.ir_row_size + 1;
  }

  getEndRange() {
    return this.state.ir_active_page * this.state.ir_row_size > this.state.ir_length
      ? this.state.ir_length
      : this.state.ir_active_page * this.state.ir_row_size;
  }

  setRoute = (uri_text, route_type) => {
    const route_base = this.props.history.location.pathname;
    let search = route_type === 'search' ? uri_text : getBusinessSearchValueFromRoute();
    let filter = route_type === 'filter' ? uri_text : getBusinessFilterValueFromRoute();
    let col = route_type === 'col' ? uri_text : getBusinessColumnValueFromRoute();
    let status = route_type === 'status' ? uri_text : getBusinessStatusValueFromRoute();
    let start_date = route_type === 'start' ? uri_text : getBusinessStartDateValueFromRoute();
    let end_date = route_type === 'end' ? uri_text : getBusinessEndDateValueFromRoute();

    search = search !== '' ? `search=${search}` : '';
    filter = filter !== '' ? `filter=${filter}` : '';
    col = col !== '' ? `col=${col}` : '';
    status = status !== '' ? `status=${status}` : '';
    start_date = start_date !== '' ? `start=${start_date}` : '';
    end_date = end_date !== '' ? `end=${end_date}` : '';

    let param = [];

    if (route_type === 'filter') {
      param.push(filter);
    } else {
      if (search) {
        param.push(search);
      }

      if (col) {
        param.push(col);
      }

      if (status) {
        param.push(status);
      }

      if (start_date) {
        param.push(start_date);
      }

      if (end_date) {
        param.push(end_date);
      }
    }

    let route_end = param.length > 0 ? '?' + param.join('&') : '';

    return `${route_base}${route_end}`;
  };

  onChangeSearchPatient = (event) => {
    onChangeSearch(this, event);
  };

  keyPress = (event) => {
    if (event.key === 'Enter') {
      this.applySearch();
    }
  };

  applySearch() {
    applyItemRequestSearch(this);
  }

  onSearchButtonClick = (event) => {
    this.applySearch();
  };

  onQueryFilterSelection = (event) => {
    ////////////////////////////////////////////////////////////////////////////
    // OLD METHOD
    // const uri_text = encodeURI(event.target.value);
    //
    // if (uri_text === 'all') {
    //   this.props.history.replace(this.setRoute('', 'col'));
    // } else {
    //   this.props.history.replace(this.setRoute(uri_text, 'col'));
    // }
    ////////////////////////////////////////////////////////////////////////////

    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);

    this.setState({
      column_filter: uri_text,
    });
  };

  onStatusFilterSelection = (event) => {
    ////////////////////////////////////////////////////////////////////////////
    // OLD METHOD
    // const uri_text = encodeURI(event.target.value);
    //
    // if (uri_text === 'all') {
    //   this.props.history.replace(this.setRoute('', 'status'));
    // } else {
    //   this.props.history.replace(this.setRoute(uri_text, 'status'));
    // }
    ////////////////////////////////////////////////////////////////////////////

    let uri_text = encodeURI(event.target.value) === 'all' ? '' : encodeURI(event.target.value);
    this.setState({
      status_filter: uri_text,
    });
  };

  onStartDateSelection = (event) => {
    ////////////////////////////////////////////////////////////////////////////
    // OLD METHOD
    // const uri_text = encodeURI(event.target.value);
    // const temp_date = new Date(uri_text).getTime();
    //
    // if (uri_text === '') {
    //   this.props.history.replace(this.setRoute('', 'start'));
    // } else {
    //   this.props.history.replace(this.setRoute(uri_text, 'start'));
    // }
    ////////////////////////////////////////////////////////////////////////////
    this.setState({
      start_date_filter: event.target.value,
    });
  };

  onEndDateSelection = (event) => {
    ////////////////////////////////////////////////////////////////////////////
    // OLD METHOD
    // const uri_text = encodeURI(event.target.value);
    // const temp_date = new Date(uri_text).getTime();
    //
    // if (uri_text === '') {
    //   this.props.history.replace(this.setRoute('', 'end'));
    // } else {
    //   this.props.history.replace(this.setRoute(uri_text, 'end'));
    // }
    ////////////////////////////////////////////////////////////////////////////

    this.setState({
      end_date_filter: event.target.value,
    });
  };

  onSideBarCollapseClick() {
    this.setState({
      side_collapse: !this.state.side_collapse,
    });
  }

  changeItemRequestType = (selected_ir_type) => {
    const state_filter = this.state.filter;

    const { all_ir_statuses, gen2_ir_raw, gen2_ir_statuses, filter, user_id } = this.state;
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const active_page = 1;
    const row_size = this.state.ir_row_size;

    const ir_raw = gen2_ir_raw;

    const ir_view = filter
      ? getItemRequestsFromFilter(ir_raw, filter, user_id)
      : getItemRequestsFromSearch(
          getItemRequestsFromEndDate(getItemRequestsFromStartDate(getItemRequestsFromStatus(ir_raw, status), start_date), end_date),
          this.state.search,
          column
        );
    const ir_sort_method = this.getSortMethodByFilterSelection(state_filter, filter, this.state.ir_sort_method);
    if (selected_ir_type === 'gen2-Ir') {
      this.setState({
        selected_ir_type: 'Gen 2.0 Item Requests',
        statuses: gen2_ir_statuses,
        ir_raw: ir_raw,
        ir_active_page: active_page,
        ir_view: getItemRequestsFromRow(
          this.setOrderByMethod(ir_view, ir_sort_method, this.state.ir_sort_order_ascending[ir_sort_method]),
          active_page,
          row_size
        ),
        ir_length: ir_view.length,
      });
    } else {
      this.setState({
        selected_ir_type: 'All Item Requests',
        statuses: all_ir_statuses,
        ir_raw: this.state.all_ir_raw,
        ir_view: this.state.all_ir_view,
        ir_length: this.state.all_ir_length,
        ir_active_page: active_page,
      });
    }
  };

  onClickSubMenu = (filter) => {
    this.setState({ submission_date_error: false });
    this.props.history.replace(this.setRoute(filter, 'filter'));
    this.onPageChange(1);
  };

  orderIrsByIreqId(item_request, sort) {
    return _.orderBy(item_request, ['ireq_id'], [sort]);
  }

  orderIrsByDoctorName(item_request, sort) {
    return _.orderBy(item_request, ['doctor_name', 'submission_date_priority'], [sort, getSubmissionDateDefaultSort()]);
  }

  orderIrsByName(item_request, sort) {
    return _.orderBy(item_request, ['first_name', 'last_name', 'submission_date_priority'], [sort, sort, getSubmissionDateDefaultSort()]);
  }

  orderCasesByPatientName(item_request, sort) {
    return _.orderBy(item_request, ['patient_name'], [sort]);
  }

  orderIrsBySubmissionDate(item_request, sort) {
    return _.orderBy(item_request, ['created_date', 'status_priority'], [sort]);
  }

  orderIrsByStatusDate(item_request, sort) {
    return _.orderBy(item_request, ['status_date', 'status_priority'], [sort]);
  }

  orderIrsByTargetShipDate(item_request, sort) {
    if (this.state.filter === 'late') {
      return _.orderBy(item_request, ['internal_target_ship_date', 'status_date', 'doctor_name'], [sort, 'asc', 'asc']);
    }
    return _.orderBy(item_request, ['internal_target_ship_date', 'status_date'], [sort]);
  }

  /**
   * Sorts item request by production work order reference number
   * @function
   * @param {Array} item_request - Array of Item Request
   * @param {String} sort - sort method that is being used asc or desc
   * @return {Array} List of cases sorted
   */
  orderIrsByProductionWorkOrderRef(item_request, sort) {
    return _.orderBy(item_request, ['production_wo_present', 'submission_date_priority'], [sort]);
  }

  /**
   * Sorts item request by status but takes in account ship date value
   * @function
   * @param {Array} item_request - Array of Item Request
   * @param {String} sort - sort method that is being used asc or desc
   * @return {Array} List of cases sorted
   */
  orderIrsByStatus(item_request, sort) {
    return _.orderBy(item_request, ['status_priority', 'ship_date_val', 'submission_date_priority'], [sort, sort]);
  }

  refreshRoute = (event) => {
    if (!this.state.side_collapse) {
      this.props.history.replace(this.props.history.location.pathname);

      this.setState({
        refresh: true,
        status_filter: '',
      });
    }
  };

  onSortClick = (event) => {
    const sort_method = event.currentTarget.dataset.method;
    const column = getBusinessColumnValueFromRoute() ? this.state.column_filter : '';
    const status = getBusinessStatusValueFromRoute() ? this.state.status_filter : '';
    const start_date = getBusinessStartDateValueFromRoute() ? this.state.start_date_filter : '';
    const end_date = getBusinessEndDateValueFromRoute() ? this.state.end_date_filter : '';
    const filter = getBusinessFilterValueFromRoute() ? this.state.filter : '';
    let ir_sort_order_ascending = this.state.ir_sort_order_ascending;

    if (sort_method === this.state.ir_sort_method) {
      //Flip order
      ir_sort_order_ascending[sort_method] = !ir_sort_order_ascending[sort_method];
    }

    let ir_view = filter
      ? getItemRequestsFromFilter(this.state.ir_raw, filter, this.state.user_id)
      : getItemRequestsFromSearch(
          getItemRequestsFromEndDate(getItemRequestsFromStartDate(getItemRequestsFromStatus(this.state.ir_raw, status), start_date), end_date),
          this.state.search,
          column
        );

    ir_view = getItemRequestsFromRow(
      this.setOrderByMethod(ir_view, sort_method, ir_sort_order_ascending[sort_method]),
      this.state.ir_active_page,
      this.state.ir_row_size
    );

    this.setState({
      ir_view: ir_view,
      ir_sort_method: sort_method,
      ir_sort_order_ascending: ir_sort_order_ascending,
    });
  };

  setOrderByMethod(ir_view, sort_method, sort) {
    const sort_value = this.getSortValue(sort);

    switch (sort_method) {
      case 'ireq_id':
        ir_view = this.orderIrsByIreqId(ir_view, sort_value);
        break;

      case 'doctor_name':
        ir_view = this.orderIrsByDoctorName(ir_view, sort_value);
        break;

      case 'patient_name':
        ir_view = this.orderIrsByName(ir_view, sort_value);
        break;

      case 'status':
        ir_view = this.orderIrsByStatus(ir_view, sort_value);
        break;

      case 'created_date':
        ir_view = this.orderIrsBySubmissionDate(ir_view, sort_value);
        break;

      case 'status_date':
        ir_view = this.orderIrsByStatusDate(ir_view, sort_value);
        break;

      case 'target_ship_date':
        ir_view = this.orderIrsByTargetShipDate(ir_view, sort_value);
        break;

      case 'production_wo':
        ir_view = this.orderIrsByProductionWorkOrderRef(ir_view, sort_value);
        break;

      default:
    }

    return ir_view;
  }

  /**
   * Looks at how long the word count is for a given digit count for the badge
   * @function
   * @param {String} badge_name - Contains the filter code name associated with the badge
   * @return {Number} Returns a word count length of the badge value
   */
  badgeCharCount = (badge_name) => {
    let word_count = '';

    if (this.state.statuses && this.state.statuses[badge_name]) {
      word_count = this.state.statuses[badge_name].toString();
    }

    return word_count.length;
  };

  /**
   * Right click handler opens context menu
   * @function
   */
  onIrRightClick = (event) => {
    this.setState({
      newTabUrl: `/business/portal/case/${event.currentTarget.dataset.caseid}/item`,
    });
  };

  /**
   * Creates a single sidebar filter configuration
   * @function
   * @param {String} filter_key - The id of the filter
   * @param {String} text - The display text of the filter
   * @param {Array} [submenu=undefined] - The list of submenu under the filter
   * @returns {Object} A single filter config
   */
  buildSingleSidebarFilter(filter_key, text, submenu = undefined) {
    const { filter, statuses } = this.state;
    return {
      filter: filter_key,
      text: text,
      count: statuses[filter_key],
      active: filter === filter_key,
      onClick: () => this.onClickSubMenu(filter_key),
      submenu: submenu,
    };
  }

  /**
   * Creates the list of filters configurations
   * @function
   * @returns {Array} The result menu filters
   */
  buildSidebarFilters() {
    return [
      this.buildSingleSidebarFilter('inbrace_action', 'InBrace Action Required', [
        this.buildSingleSidebarFilter('review', 'Review Item Request'),
        this.buildSingleSidebarFilter('prepare', 'Fabricate Item Request'),
      ]),
      this.buildSingleSidebarFilter('ship', 'Shipped Item Requests'),
      this.buildSingleSidebarFilter('hold', 'Held Item Requests'),
      this.buildSingleSidebarFilter('cancel', 'Cancelled Item Requests'),
      this.buildSingleSidebarFilter('activeexp', 'Active Expedited Item Requests'),
      this.buildSingleSidebarFilter('pendingexp', 'Pending Expedited Requests'),
      this.buildSingleSidebarFilter('late', 'Late Item Requests'),
    ];
  }

  /**
   * Creates the list of dropdown menu
   * @function
   * @returns {List} - The list of sidebar dropdown menu
   */
  buildSidebarDropdown() {
    return [
      {
        key: 'All Item Requests',
        text: 'All Item Requests',
        onClick: () => this.changeItemRequestType('all-item-requests'),
      },
      {
        key: 'Gen 2.0 Item Requests',
        text: 'Gen 2.0 Item Requests',
        onClick: () => this.changeItemRequestType('gen2-Ir'),
      },
    ];
  }

  render() {
    if (this.state.error) {
      return (
        <div className="fullview">
          <NotFound />
        </div>
      );
    } else if (this.state.loading) {
      return <Loader />;
    } else {
      // figuring out the date method
      const date_method = this.isFilterDoctorAction()
        ? 'doctor_action_date'
        : this.isFilterNewUpload()
        ? 'doctor_new_record_date'
        : this.isFilterNewSetupUpload()
        ? 'doctor_setup_date'
        : '';

      return (
        <div>
          <Helmet>
            <title>Item Request List | InBrace Business Portal</title>
          </Helmet>
          <CaseListSidebar side_collapse={this.state.side_collapse} onClickCollapse={this.onSideBarCollapseClick}>
            <div className="sidebar__top-container">
              <CaseListDropdown selected={this.state.selected_ir_type} dropdown_list={this.buildSidebarDropdown()} />
            </div>
            <CaseListFilter filters={this.buildSidebarFilters()} />
          </CaseListSidebar>

          <div className={this.state.side_collapse ? 'sidenav-submenu-offset submenu-collapse' : 'sidenav-submenu-offset'}>
            <div className="main-content">
              <div className="dark-options">
                <div className="page-heading">Item Request List</div>
                <div className="dark-search">
                  <input
                    type="text"
                    className="form-control search-bar-dark font-awesome"
                    placeholder="Search"
                    aria-describedby="basic-addon1"
                    onChange={this.onChangeSearchPatient}
                    onKeyPress={this.keyPress}
                    value={this.state.search}
                  />
                </div>
                <div className="dark-filter dark-filter-hide">
                  <div className="select-container">
                    <select className="select-filter" value={this.state.column_filter} onChange={this.onQueryFilterSelection}>
                      <option value="all">All</option>
                      <option value="ireqid">Request ID</option>
                      <option value="doctor">Doctor Name</option>
                      <option value="patient">Patient Name</option>
                      {this.hasAssignTo() ? <option value="assign">Assigned To</option> : null}
                    </select>
                  </div>
                </div>
                <div className="dark-filter">
                  <div className="select-container">
                    <select className="select-status" value={this.state.status_filter} onChange={this.onStatusFilterSelection}>
                      <option value="">All Status</option>
                      <option value="review">{translateStatus('INBRACE Reviewing Request')}</option>
                      <option value="prepare">{new_branding_words(translateStatus('INBRACE Manufacturing Item Request'))}</option>
                      <option value="ship">{translateStatus('Item Request Shipped')}</option>
                      <option value="hold">{translateStatus('Hold')}</option>
                      <option value="cancel">{translateStatus('Cancelled')}</option>
                    </select>
                  </div>
                </div>
                <div className="dark-filter">
                  <div className="select-container-date">
                    <div className="text-center light-gray-text">Submission Date Between</div>
                    <div>
                      <input
                        className={'select-date-l ' + (this.state.submission_date_error ? 'date-error' : '')}
                        type="date"
                        name="start"
                        value={this.state.start_date_filter}
                        onChange={this.onStartDateSelection}
                      />
                      <input
                        className={'select-date-l ' + (this.state.submission_date_error ? 'date-error' : '')}
                        type="date"
                        name="end"
                        value={this.state.end_date_filter}
                        onChange={this.onEndDateSelection}
                      />
                    </div>
                  </div>
                </div>
                <button className="btn btn-light-3" onClick={this.onSearchButtonClick}>
                  <i className="fa fa-search" aria-hidden="true" />
                </button>
              </div>
              {this.state.ir_view.length > 0 ? (
                <div className="table-case-view">
                  <table className="table table-condensed table-striped table-industry">
                    <thead className="table-inbrace-dark-industry">
                      <tr>
                        <th className="ireqid-width" data-method="ireq_id" onClick={this.onSortClick}>
                          <SortHeader
                            title="Request ID"
                            category="ireq_id"
                            sort_method={this.state.ir_sort_method}
                            ascending={this.state.ir_sort_order_ascending['ireq_id']}
                          />
                        </th>
                        <th className="submission-width" data-method="created_date" onClick={this.onSortClick}>
                          <SortHeader
                            title="Submission"
                            category="created_date"
                            sort_method={this.state.ir_sort_method}
                            ascending={this.state.ir_sort_order_ascending['created_date']}
                          />
                        </th>
                        <th className="name-width cozy" data-method="doctor_name" onClick={this.onSortClick}>
                          <SortHeader
                            title="Doctor"
                            category="doctor_name"
                            sort_method={this.state.ir_sort_method}
                            ascending={this.state.ir_sort_order_ascending['doctor_name']}
                          />
                        </th>
                        {this.state.filter !== 'late' && (
                          <th className="patient-width" data-method="patient_name" onClick={this.onSortClick}>
                            <SortHeader
                              title="Patient"
                              category="patient_name"
                              sort_method={this.state.ir_sort_method}
                              ascending={this.state.ir_sort_order_ascending['patient_name']}
                            />
                          </th>
                        )}
                        {this.hasAssignTo() ? <th className="name-width">Assigned To</th> : null}
                        <th className={'name-width' + (this.isFilterManufacturing() ? ' ir' : '')} data-method="status" onClick={this.onSortClick}>
                          <SortHeader
                            title="Status"
                            category="status"
                            sort_method={this.state.ir_sort_method}
                            ascending={this.state.ir_sort_order_ascending['status']}
                          />
                        </th>
                        {this.isFilterManufacturing() ? (
                          <th className="wo-width center-text" data-method="production_wo" onClick={this.onSortClick}>
                            <SortHeader
                              title="WO"
                              category="production_wo"
                              sort_method={this.state.ir_sort_method}
                              ascending={this.state.ir_sort_order_ascending['production_wo']}
                            />
                          </th>
                        ) : null}
                        {this.isFilterManufacturing() ? (
                          <th className="ir approval-width" data-method="status_date" onClick={this.onSortClick}>
                            <SortHeader
                              title="Approval Date"
                              category="status_date"
                              sort_method={this.state.ir_sort_method}
                              ascending={this.state.ir_sort_order_ascending['status_date']}
                            />
                          </th>
                        ) : this.isFilterNewUpload() || this.isFilterNewSetupUpload() || this.isFilterDoctorAction() ? (
                          <th className="approval-width" data-method={date_method} onClick={this.onSortClick}>
                            <SortHeader
                              title="Days Past"
                              category={date_method}
                              sort_method={this.state.ir_sort_method}
                              ascending={this.state.ir_sort_order_ascending[date_method]}
                            />
                          </th>
                        ) : null}
                        {this.isFilterManufacturing() || this.state.filter === 'late' ? (
                          <th
                            className={this.state.filter === 'late' ? 'manufacturing-submission-width' : 'target-ship-date-width'}
                            data-method="target_ship_date"
                            onClick={this.onSortClick}
                          >
                            <SortHeader
                              title="Target Ship Date"
                              category="target_ship_date"
                              sort_method={this.state.ir_sort_method}
                              ascending={this.state.ir_sort_order_ascending['target_ship_date']}
                            />
                          </th>
                        ) : null}
                      </tr>
                    </thead>
                    <tbody ref={this.irTable} className="table-inbrace-dark-industry context-menu-container">
                      <ContextMenu container={this.irTable} containerType="table">
                        <NewTabLink url={this.state.newTabUrl} />
                      </ContextMenu>
                      {this.state.ir_view.map((item_request, index) => {
                        return (
                          <tr
                            className="pointer"
                            key={index}
                            onMouseUp={this.onPatientClick}
                            onContextMenu={this.onIrRightClick}
                            data-requestid={item_request.ireq_id}
                            data-caseid={item_request.case_id}
                          >
                            <td className="td-industry-offset caseid-width">
                              {removeIreqIdInitialNumber(item_request.ireq_id)}
                              {item_request.late && (
                                <i
                                  className="fa fa-flag target-date-flag"
                                  aria-hidden="true"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title={`Missed Target Ship Date: ${convertDate(item_request.late)}`}
                                />
                              )}
                              <div className="tag-container">
                              {item_request.is_epp ? <span className="case-epp">EPP</span> : null}
                              {item_request.gen_2 ? <span className="item-gen2">Gen 2.0</span> : null}
                              </div>
                            </td>
                            <td>{convertDate(item_request.created_date)}</td>
                            <td>{'Dr. ' + item_request.doctor_name}</td>
                            {this.state.filter !== 'late' && <td>{item_request.patient_name}</td>}
                            {this.hasAssignTo() ? <td>{item_request.assigned_to_name}</td> : null}
                            <td>
                              {item_request.ireq_status === 'Item Request On Hold' ? (
                                <React.Fragment>
                                  <span className="hold-length-2">
                                    {translateStatus(item_request.ireq_status)} {'-'} {item_request.status_comment}
                                  </span>
                                  {item_request.expedite_ind ? (
                                    <span className="expedited-icon">
                                      <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                    </span>
                                  ) : null}
                                </React.Fragment>
                              ) : item_request.ireq_status === 'Item Request Cancelled' ? (
                                <span className="hold-length-2">
                                  {translateStatus(item_request.ireq_status)} {'-'} {item_request.status_comment}
                                </span>
                              ) : item_request.ireq_status === 'Item Request Shipped' ? (
                                <React.Fragment>
                                  <span>Item Request Shipped on {convertDate(item_request.ship_date)}</span>
                                  {item_request.expedite_ind ? (
                                    <span className="expedited-icon">
                                      <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                    </span>
                                  ) : null}
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  {new_branding_words(item_request.ireq_status)}
                                  {item_request.expedite_ind && !this.isFilterManufacturing() ? (
                                    <span className="expedited-icon">
                                      <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                    </span>
                                  ) : null}
                                </React.Fragment>
                              )}
                            </td>
                            {this.isFilterManufacturing() ? (
                              <td className="center-text">
                                {item_request.production_wo ? (
                                  <span className="center-text">
                                    <i className="fa fa-check" aria-hidden="true" />
                                  </span>
                                ) : (
                                  ''
                                )}
                              </td>
                            ) : null}
                            {this.isFilterManufacturing() || this.state.filter === 'late' ? (
                              <React.Fragment>
                                {this.state.filter !== 'late' && <td>{convertDate(item_request.status_date)}</td>}
                                <td>
                                  {item_request.internal_target_ship_date ? convertDate(item_request.internal_target_ship_date) : '--------'}
                                  {item_request.expedite_ind ? (
                                    <span className="expedited-icon">
                                      <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                    </span>
                                  ) : null}
                                </td>
                              </React.Fragment>
                            ) : this.isFilterNewUpload() ? (
                              <td>
                                {computeDaysPast(item_request.doctor_new_record_date)}
                                {item_request.expedite_ind ? (
                                  <span className="expedited-icon">
                                    <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                  </span>
                                ) : null}
                              </td>
                            ) : this.isFilterNewSetupUpload() ? (
                              <td>
                                {computeDaysPast(item_request.doctor_setup_date)}
                                {item_request.expedite_ind ? (
                                  <span className="expedited-icon">
                                    <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                  </span>
                                ) : null}
                              </td>
                            ) : this.isFilterDoctorAction() ? (
                              <td>
                                {computeDaysPast(item_request.doctor_action_date)}
                                {item_request.expedite_ind ? (
                                  <span className="expedited-icon">
                                    <FontAwesomeIcon icon={['fas', 'shipping-fast']} />
                                  </span>
                                ) : null}
                              </td>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div className={this.state.is_bottom ? 'pagination-bottom pagination-dark' : 'pagination-dark'}>
                    <PaginationContainer
                      theme="bpp"
                      isBottom={this.state.is_bottom}
                      activePage={this.state.ir_active_page}
                      itemsCountPerPage={this.state.ir_row_size}
                      totalItemsCount={this.state.ir_length}
                      pageRangeDisplayed={this.state.ir_page_range}
                      onChange={this.onPageChange}
                      startRange={this.getStartRange()}
                      endRange={this.getEndRange()}
                      type="Item Requests"
                    />
                  </div>
                </div>
              ) : (
                searchErrorMessage(getBusinessSearchValueFromRoute(), 'item requests')
              )}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(IRContent);
