import Axios from 'axios';
import _ from 'lodash';
import { fetchWireSelectionStatus } from './wire_selection';

export const SET_WIRE_FABRICATION_REVIEW_STATUS = 'SET_WIRE_FABRICATION_REVIEW_STATUS';
export const SET_WIRE_FABRICATION_REVIEW_STATUS_LOG = 'SET_WIRE_FABRICATION_REVIEW_STATUS_LOG';
export const SET_SELECTED_PRODUCTION_STAGE = 'SET_SELECTED_PRODUCTION_STAGE';
export const SET_INITIATE_WIRE_FABRICATION_REVIEW_MODAL = 'SET_INITIATE_WIRE_FABRICATION_REVIEW_MODAL';
export const SET_WIRE_FABRICATION_REVIEW_CONFIRM_MODAL = 'SET_WIRE_FABRICATION_REVIEW_CONFIRM_MODAL';
export const SET_WS_WIRES = 'SET_WS_WIRES';
export const SET_WIRE_FABRICATION_REVIEW_ERROR = 'SET_WIRE_FABRICATION_REVIEW_ERROR';
export const SET_WIRES_PREV_REV = 'SET_WIRES_PREV_REV';
export const SET_WIRE_FABRICATION_REVIEW_RESOLVE_MODAL = 'SET_WIRE_FABRICATION_REVIEW_RESOLVE_MODAL';
export const SET_PRODUCTION_LOG_MODAL = 'SET_PRODUCTION_LOG_MODAL';
export const SET_REFRESH = 'SET_REFRESH';

/**
 * Retrieves wire fabrication review status
 * @function
 * @param {string} case_id - Case id
 * @returns {object} - Action type
 */
export function fetchWireFabricationReviewStatus(case_id) {
  return (dispatch) => {
    Axios.get(`/apiv3/wirefabricationreview/${case_id}`)
      .then((res) => {
        if (res && res.data) {
          dispatch(setWireFabricationReviewStatus(res.data.status));
          dispatch(setWireFabricationReviewStatusLog(res.data.status_log));
          dispatch(setWires(res.data.wires));
          dispatch(setWiresPrevRev(res.data.wires_prev_rev));
          dispatch(setSelectedProductionStage(res.data.selected_production_stage));
        }
      })
      .catch((error) => {});
  };
}

/**
 * Sets wire fabrication review status
 * @function
 * @param {string} status - Wire fabrication review status
 * @returns {object} - Action type
 */
export function setWireFabricationReviewStatus(status) {
  return (dispatch) => {
    dispatch({
      type: SET_WIRE_FABRICATION_REVIEW_STATUS,
      status: status,
    });
    if (status) {
      dispatch(setSelectedProductionStage('wire_fabrication_review'));
    }
  };
}

/**
 * Sets wire fabrication review status history log
 * @function
 * @param {list} status_log - Status log
 * @returns {object} - Action type
 */
export function setWireFabricationReviewStatusLog(status_log) {
  return {
    type: SET_WIRE_FABRICATION_REVIEW_STATUS_LOG,
    status_log: status_log,
  };
}

/**
 * Sets wires
 * @function
 * @param {list} wires - List of case's wires
 * @returns {object} - Action type
 */
export function setWires(wires) {
  return { type: SET_WS_WIRES, wires: wires };
}

/**
 * Sets Refresh State for Wire Fabrication Review
 * @param {Boolean} status
 * @returns
 */
export function setRefresh(status) {
  return { type: SET_REFRESH, status: status };
}

/**
 * Sets wires from previous revision
 * @function
 * @param {list} wires_prev_rev - Previous rev wires
 * @returns {object} - Action type
 */
export function setWiresPrevRev(wires_prev_rev) {
  return { type: SET_WIRES_PREV_REV, wires_prev_rev: wires_prev_rev };
}

/**
 * Sets selected production stage
 * @function
 * @param {object} stage - Stage to switch to
 * @returns {object} - Action type
 */
export function setSelectedProductionStage(stage) {
  return { type: SET_SELECTED_PRODUCTION_STAGE, stage: stage };
}

/**
 * Updates selected production stage through api
 * @function
 * @param {string} case_id - Case id
 * @param {string} stage - Stage to switch to
 * @returns {object} - Action type
 */
export function onProductionStageChange(case_id, stage) {
  return (dispatch) => {
    const stage_name = stage.toLowerCase().includes('label') ? 'labels' : 'wire_fabrication_review';
    const data = { stage: stage_name };
    Axios.put(`/apiv3/wirefabricationreview/${case_id}/update_stage`, data)
      .then((res) => {
        if (res && res.data) {
          dispatch(setSelectedProductionStage(res.data.selected_production_stage));
        }
      })
      .catch((error) => {});
  };
}

/**
 * Opens/closes initiate wire fabrication review modal
 * @function
 * @param {boolean} open - Open or close
 * @returns {object} - Action type
 */
export function toggleInitiateWireFabricationReviewModal(open) {
  return { type: SET_INITIATE_WIRE_FABRICATION_REVIEW_MODAL, open: open };
}

/**
 * Initiates wire fabrication review
 * @function
 * @param {string} case_id - Case id
 * @param {function} set_label_generation_status - Set label generation status
 * @param {string} label_generation_status - Current label generation status
 * @param {function} reload_information - Reload case details
 * @param {string} wfr_stage - Type of initiation: initial, in-progress, or completed
 * @returns {object} - Action type
 */
export function initiateWireFabricationReview(case_id, set_label_generation_status, label_generation_status, reload_information, wfr_stage) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    const data = { wfr_stage: wfr_stage, prev_wires: wires };
    Axios.post(`/apiv3/wirefabricationreview/${case_id}/initiate_wire_fabrication_review`, data)
      .then(function (res) {
        if (res && res.data) {
          dispatch(setWireFabricationReviewStatus(res.data.status));
          dispatch(setWires(res.data.wires));
          dispatch(toggleInitiateWireFabricationReviewModal(false));
          if (label_generation_status === 'Production Process Complete') {
            reload_information();
            set_label_generation_status('Labels Complete');
          }
        }
      })
      .catch(function (err) {
        dispatch(toggleInitiateWireFabricationReviewModal(false));
        if (err && err.response && err.response.status === 409) dispatch(setRefresh(true));
      });
  };
}

/**
 * Updates wire status to success or failure
 * @function
 * @param {object} event - Event object
 * @param {object} action - Action object
 * @param {string} case_id - Case id
 * @param {number} wire_id - Wire id
 * @param {string} prev_selection - Previous status
 * @returns {object} - Action type
 */
export function updateWireStatus(event, action, case_id, wire_id, prev_selection) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    if (prev_selection !== event.value) {
      const wire_data = {
        wire_id: wire_id,
        value: event.value,
        update_field: action.name,
        prev_wires: wires,
      };
      dispatch(updateWire(case_id, wire_data));
    }
  };
}

/**
 * Handles event when user updates wire failure info
 * @function
 * @param {object} event - Event object
 * @param {object} action - Action object
 * @param {string} case_id - Case id
 * @param {number} wire_id - Wire id
 * @param {string} break_location_id - Break location id
 * @returns {object} - Action type
 */
export function updateWireFailure(event, action, case_id, wire_id, break_location_id = null) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    const wire_data = {
      wire_id: wire_id,
      break_location_id: break_location_id,
      update_field: action.name,
      value: event.value,
      prev_wires: wires,
    };
    dispatch(updateWire(case_id, wire_data));
  };
}

/**
 * Creates new empty break location
 * @function
 * @param {string} case_id - Case id
 * @param {number} wire_id - Wire id
 * @returns {object} - Action type
 */
export function createBreakLocation(case_id, wire_id) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    const wire_data = { wire_id: wire_id, prev_wires: wires };
    dispatch(updateWire(case_id, wire_data));
  };
}

/**
 * Removes break location
 * @function
 * @param {string} case_id - Case id
 * @param {number} wire_id - Wire id
 * @param {string} break_location_id - Break location id
 * @returns {object} - Action type
 */
export function removeBreakLocation(case_id, wire_id, break_location_id) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    const wire_data = { wire_id: wire_id, break_location_id: break_location_id, prev_wires: wires };
    dispatch(updateWire(case_id, wire_data));
  };
}

/**
 * API call for updating wire
 * @function
 * @param {string} case_id - Case id
 * @param {object} wire_data - Request data with wire failure updates
 * @returns {object} - Action type
 */
export function updateWire(case_id, wire_data) {
  return (dispatch) => {
    dispatch(setWireFabricationReviewError(false));
    Axios.put(`/apiv3/wirefabricationreview/${case_id}/update_wire`, wire_data)
      .then(function (res) {
        if (res && res.data) {
          dispatch(setWires(res.data.wires));
        }
      })
      .catch(function (err) {
        dispatch(setRefresh(true));
      });
  };
}

/**
 * Opens/closes wire fabrication review confirmation modal
 * @function
 * @param {boolean} open - Open or close
 * @returns {object} - Action type
 */
export function toggleWireFabricationReviewConfirmModal(open) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    if (isWireFabricationReviewComplete(wires) && !hasBreakLocationError(wires)) {
      return dispatch({
        type: SET_WIRE_FABRICATION_REVIEW_CONFIRM_MODAL,
        open: open,
      });
    }
    dispatch(setWireFabricationReviewError(true));
  };
}

/**
 * Determines if there is any missing input in wire fabrication review
 * @function
 * @param {list} wires - List of wires
 * @returns {boolean} - True or false
 */
function isWireFabricationReviewComplete(wires) {
  const failed_wires = wires.filter((wire) => {
    return wire.status === 'Failure';
  });
  for (const wire of failed_wires) {
    if (!wire.comment || !wire.comment.course_of_action) return false;
    for (const bl of wire.comment.break_locations) {
      const location = bl.location;
      if (!location.arch_1 || !location.arch_2 || !location.tooth_1 || !location.tooth_2 || !bl.process_step) return false;
    }
  }
  return true;
}

/**
 * Determines if wires have incorrect break location input
 * @function
 * @param {list} wires - List of wires
 * @returns {boolean} - True or false
 */
function hasBreakLocationError(wires) {
  for (const wire of wires) {
    if (wire.comment && wire.comment.break_locations) {
      const break_locations = _.cloneDeep(wire.comment.break_locations);
      for (const bl of break_locations) {
        if (!Object.values(bl.location).some((l) => !l)) {
          const is_same_tooth = bl.location.arch_1 === bl.location.arch_2 && bl.location.tooth_1 === bl.location.tooth_2;
          const has_duplicate_or_inverse_location = hasDuplicateOrInverseLocation(break_locations, bl);
          if (is_same_tooth || has_duplicate_or_inverse_location) return true;
        }
      }
    }
  }
  return false;
}

/**
 * Determines if given location has another duplicate or inverse location
 * @function
 * @param {list} all_break_locations - All break locations of the wire
 * @param {object} break_location - Given break location
 * @returns {boolean} - True or false
 */
export function hasDuplicateOrInverseLocation(all_break_locations, break_location) {
  return all_break_locations.some((bl) => {
    if (bl.id !== break_location.id) {
      const location = break_location.location;
      const has_duplicate = JSON.stringify(bl.location) === JSON.stringify(location);
      const has_inverse =
        location.arch_1 === bl.location.arch_2 &&
        location.tooth_1 === bl.location.tooth_2 &&
        location.arch_2 === bl.location.arch_1 &&
        location.tooth_2 === bl.location.tooth_1;

      return has_duplicate || has_inverse;
    }
    return false;
  });
}

/**
 * Sets wire fabrication review input error
 * @function
 * @param {boolean} error - True or false
 * @returns {object} - Action type
 */
export function setWireFabricationReviewError(error) {
  return { type: SET_WIRE_FABRICATION_REVIEW_ERROR, error: error };
}

/**
 * Rework wires
 * @function
 * @param {string} case_id - Case id
 * @param {function} reload_information - Case details reload
 * @returns {object} - Action type
 */
export function reworkWireSelection(case_id, reload_information) {
  return (dispatch) => {
    Axios.post(`/apiv3/wirefabricationreview/${case_id}/rework`)
      .then(function (res) {
        if (res && res.data) {
          reload_information();
          dispatch(fetchWireSelectionStatus(case_id));
          dispatch(toggleWireFabricationReviewConfirmModal(false));
          dispatch(setWireFabricationReviewStatus(res.data.status));
          dispatch(setWires(res.data.wires));
          dispatch(setWiresPrevRev(res.data.wires_prev_rev));
        }
      })
      .catch(function (err) {
        dispatch(toggleWireFabricationReviewConfirmModal(false));
        if (err && err.response && err.response.status === 409) dispatch(setRefresh(true));
      });
  };
}

/**
 * Opens/closes resolve wire fabrication review modal
 * @function
 * @param {boolean} open - Open or close
 * @returns {object} - Action type
 */
export function toggleWireFabricationReviewResolveModal(open) {
  return {
    type: SET_WIRE_FABRICATION_REVIEW_RESOLVE_MODAL,
    open: open,
  };
}

/**
 * Resolve wire fabrication review
 * @function
 * @param {string} case_id - Case id
 * @param {function} reload_information - Case details reload
 * @param {function} set_label_generation_status - Set label generation status
 * @param {string} label_generation_status - Current label generation status
 * @returns {object} - Action type
 */
export function resolveWireFabricationReview(case_id, reload_information, set_label_generation_status, label_generation_status) {
  return (dispatch, getState) => {
    const { wires } = getState().wireFabricationReviewReducer;
    const data = { prev_wires: wires };
    Axios.post(`/apiv3/wirefabricationreview/${case_id}/resolve`, data)
      .then(function (res) {
        if (res && res.data) {
          reload_information();
          if (label_generation_status === 'Labels Complete') {
            set_label_generation_status('Production Process Complete');
          }
          dispatch(toggleWireFabricationReviewResolveModal(false));
          dispatch(setWireFabricationReviewStatus(res.data.status));
          dispatch(setWireFabricationReviewStatusLog(res.data.status_log));
        }
      })
      .catch(function (err) {
        dispatch(toggleWireFabricationReviewResolveModal(false));
        if (err && err.response && err.response.status === 409) dispatch(setRefresh(true));
      });
  };
}

export function toggleProductionLogModal(open) {
  return {
    type: SET_PRODUCTION_LOG_MODAL,
    production_log_modal: open,
  };
}
